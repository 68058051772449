import React, { createContext, useContext, useState } from 'react';
import axios from "axios";
import Cookies from 'js-cookie';
import none_trainer_one from "../../assets/images/none_trainer_one.png"

const ShopContext = createContext(undefined);

export const ShopProvider = ({ children }) => {
    const [shopList, setshopList] = useState([]);

    React.useEffect(() => {
        var shopListO = [];

        for (var i = 0; i < 12; i++){
            var class_this = 'shop__item feature-empty fix_block_cursor';

            shopListO.push({
                img: none_trainer_one,
                name: "Аватар тренера № ..",
                price: '.... ₽',
                link: i,
                class: class_this
            });
        }

        setshopList(shopListO);
    }, []);

    const load = async () => {
        const asyncFn = async () => {
            await axios.get(process.env.REACT_APP_NEW_API + '/debug_menu/get_avatars', {
                headers: {
                    'Content-Type': 'application/json',
                    'trainer_token': Cookies.get('session_id')
                }
            }).then(function (response) {
                if (response.status === 200) {

                    var data = response.data.data;
                    var shopListO = [];

                    for (var i = 0; i < data.length; i++){
                        var class_this = 'shop__item';
                        if('https://storage.yandexcloud.net/qastudio/pokemon_battle/trainers/'+('' + data[i].id).padStart(2, '0')+'.png' === Cookies.get('user_image')){
                            class_this += ' feature-empty fix_block_cursor';
                        }else{
                            class_this += ' available';
                        }

                        shopListO.push({
                            img: 'https://storage.yandexcloud.net/qastudio/pokemon_battle/trainers/'+('' + data[i].id).padStart(2, '0')+'.png',
                            name: "Аватар тренера № "+data[i].id,
                            price: data[i].price+' ₽',
                            link: data[i].id,
                            class: class_this
                        });

                        Cookies.set('order_type', 'avatar', { expires: 365 });
                    }

                    setshopList(shopListO);
                }
            })
        };

        asyncFn();
    };

    const value = { shopList, load };

    return <ShopContext.Provider value={value}>{children}</ShopContext.Provider>;
};

export const useShop = () => useContext(ShopContext);