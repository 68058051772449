import React from 'react';
import {useAuth} from "../../../pages/UserCore/Auth/AuthContext";
import {generateTrainerImageUrl} from "../../../helpers/helpers";
import {useNavigate} from "react-router-dom";

const HeaderUserBlock = () => {
    const navigate = useNavigate();
    const { user } = useAuth();

    const handleLinkSelfTrainer = (e) => {
        if (user){
            navigate('/trainer/'+user.id+'');
        }
    };

    return (
        <div className="header_card_trainer style_1_interactive_button_link" onClick={handleLinkSelfTrainer}>
            <div className="header_card_trainer_id">
                <div className="header_card_trainer_id_title">ID</div>
                <div className="header_card_trainer_id_num">{user?.id ? user.id : ''}</div>
            </div>
            <div className="header_card_trainer_img">
                <img className="k_main_photo" src={generateTrainerImageUrl(user?.avatar_id ? user.avatar_id : '')} alt="логотип" />
            </div>
        </div>
    );
};

export default HeaderUserBlock;