import React, {useState} from 'react';
import {useTrainerOne} from "./TrainerOneContext";
import "./TrainerOne.css";
import "../TrainerAndPokemonOnePage.css"

import {useAuth} from "../UserCore/Auth/AuthContext";

import CopyNumber from "../../components/Interactive/CopyNumber/CopyNumber";
import BackButton from "../../components/Interactive/BackButton/BackButton";

import base_premium_icon from "../../assets/icons/base_premium_icon.png"
import base_cup_icon from "../../assets/icons/base_cup_icon.png"
import none_trainer_one from "../../assets/images/none_trainer_one.png"

import PokemonHistoryList from "../../components/Pokemons/PokemonHistoryList/PokemonHistoryList";
import {generateTrainerImageUrl, GetIdRarery} from "../../helpers/helpers";
import TrainerAchievements from "../../components/Pokemons/TrainerOne/TrainerAchievements/TrainerAchievements";
import PokemonPokemonList from "../../components/Pokemons/PokemonPokemonList/PokemonPokemonList";
import PokemonExport from "../../components/Pokemons/TrainerOne/PokemonExport/PokemonExport";
import PokemonPremium from "../../components/Pokemons/PokemonPremium/PokemonPremium";
import PokemonAvatarChange from "../../components/Pokemons/PokemonAvatarChange/PokemonAvatarChange";
import PokemonUserSettings from "../../components/Pokemons/PokemonUserSettings/PokemonUserSettings";
import {useParams} from "react-router-dom";

const TrainerOnePage = () => {
    const [isAllowRender, setisAllowRender] = useState(false);
    const { user } = useAuth();
    const { trainer, achievements, battles, pokemons, rate, getAllInfo } = useTrainerOne();

    const params = useParams();

    var trainer_id = params.trainerId;
    var trainer_level_fix = trainer.level;
    var in_pokeball = 0;

    React.useEffect(() => {
        setisAllowRender(false)

        const fetchData = async () => {
            await getAllInfo({ trainer_id }).then(function (){
                setisAllowRender(true)
            })
        }

        fetchData();
    },[params.trainerId])

    if (trainer.level > 5 && isAllowRender){
        trainer_level_fix = 5;
    }

    if (trainer.pokemons_in_pokeballs && trainer.pokemons_in_pokeballs.length > 0 && isAllowRender){
        in_pokeball = trainer.pokemons_in_pokeballs.length;
    }

    return (
        <div className="single_page">
            <div className="single_page_body">
                    <BackButton />
                <div className="single_page_body_avatar">
                    <div className="single_page_body_avatar_gradient" style={{"left": "-46.5px"}}></div>
                    <img className="single_page_body_avatar_img" src={isAllowRender ? generateTrainerImageUrl(trainer.avatar_id) : none_trainer_one} alt="image" />
                </div>
                <div className="single_page_body_content">
                    <div className="single_page_body_content_title">
                        <p className="single_page_body_content_title_text style_1_caption_16_500 style_1_text_left">
                            {trainer.level >= 5 && isAllowRender ? 'Чемпион' : 'Тренер'}
                        </p>
                        <div className="single_page_body_content_title_icons">
                            <h2 className="single_page_body_content_title_icons_name style_1_heading_38_400_pokemon_classic style_1_text_left">{isAllowRender ? trainer.trainer_name : '...... .....'}</h2>
                            {trainer.level >= 5 && isAllowRender ? <div className="single_page_body_content_title_icon single_page_body_content_title_icon_cup">
                                <img src={base_cup_icon} className="single_page_body_content_title_icon_img" alt="иконка кубка" />
                            </div> : ''}
                            {trainer.is_premium && isAllowRender ? <div className="single_page_body_content_title_icon pokemon_one_body_content_title_icon_gold">
                                <img src={base_premium_icon} className="single_page_body_content_title_icon_img" alt="иконка премиума" />
                            </div> : ''}
                        </div>
                    </div>
                    <div className="single_page_body_content_inner">
                        <div className="single_page_body_content_inner_top">
                            <div className="single_page_body_content_inner_top_list_attr single_page_body_content_inner_box">
                                {rate > 0 && isAllowRender ?
                                    <div className="attr_rate_trainer single_page_body_content_inner_top_list_attr_one">
                                        <span className="single_page_body_content_inner_top_list_attr_one_name style_1_caption_16_400 style_1_text_left">Место</span>
                                        <div className="single_page_body_content_inner_top_list_attr_one_text style_1_caption_16_400 style_1_text_left">
                                            {rate}
                                            <div className={"k_cart_trainer_rate_img k_cart_trainer_rate_img_"+((rate > 3 && rate < 30) ? (rate > 10 && rate < 30) ? 30 : 10 : rate)}></div>
                                        </div>
                                    </div> : ''}
                                <div className="attr_id_trainer single_page_body_content_inner_top_list_attr_one">
                                    <span className="single_page_body_content_inner_top_list_attr_one_name style_1_caption_16_400 style_1_text_left">ID тренера</span>
                                    <div className="single_page_body_content_inner_top_list_attr_one_text style_1_caption_16_400 style_1_text_left">
                                        <CopyNumber id={isAllowRender ? trainer.id : '....'} />
                                    </div>
                                </div>
                                <div className="attr_city_trainer single_page_body_content_inner_top_list_attr_one">
                                    <span className="single_page_body_content_inner_top_list_attr_one_name style_1_caption_16_400 style_1_text_left">Город</span>
                                    <div className="single_page_body_content_inner_top_list_attr_one_text style_1_caption_16_400 style_1_text_left">
                                        {isAllowRender ? trainer.city : '.....'}
                                    </div>
                                </div>
                                <div className="single_page_body_content_inner_top_list_attr_one">
                                    <span className="single_page_body_content_inner_top_list_attr_one_name style_1_caption_16_400 style_1_text_left">Покеболы</span>
                                    <span className="single_page_body_content_inner_top_list_attr_one_text style_1_caption_16_400 style_1_text_left">{isAllowRender ? in_pokeball : '.'}</span>
                                    <ul className="single_page_body_content_inner_top_list_attr_one_slide">
                                        <li className="single_page_body_content_inner_top_list_attr_one_slide_i" data-active={isAllowRender ? in_pokeball : 0} data-full="3"></li>
                                        <li className="single_page_body_content_inner_top_list_attr_one_slide_i" data-active={isAllowRender ? in_pokeball : 0} data-full="3"></li>
                                        <li className="single_page_body_content_inner_top_list_attr_one_slide_i" data-active={isAllowRender ? in_pokeball : 0} data-full="3"></li>
                                    </ul>
                                </div>
                                <div className="single_page_body_content_inner_top_list_attr_one">
                                    <span className="single_page_body_content_inner_top_list_attr_one_name style_1_caption_16_400 style_1_text_left">Уровень</span>
                                    <span className="single_page_body_content_inner_top_list_attr_one_text style_1_caption_16_400 style_1_text_left">{isAllowRender ? trainer.level : '.'}</span>
                                    <ul className="single_page_body_content_inner_top_list_attr_one_slide">
                                        <li className="single_page_body_content_inner_top_list_attr_one_slide_i" data-active={isAllowRender ? trainer_level_fix : 0} data-full="5"></li>
                                        <li className="single_page_body_content_inner_top_list_attr_one_slide_i" data-active={isAllowRender ? trainer_level_fix : 0} data-full="5"></li>
                                        <li className="single_page_body_content_inner_top_list_attr_one_slide_i" data-active={isAllowRender ? trainer_level_fix : 0} data-full="5"></li>
                                        <li className="single_page_body_content_inner_top_list_attr_one_slide_i" data-active={isAllowRender ? trainer_level_fix : 0} data-full="5"></li>
                                        <li className="single_page_body_content_inner_top_list_attr_one_slide_i" data-active={isAllowRender ? trainer_level_fix : 0} data-full="5"></li>
                                        <li className="single_page_body_content_inner_top_list_attr_one_slide_i" data-active={isAllowRender ? trainer_level_fix : 0} data-full="5"></li>
                                        <li className="single_page_body_content_inner_top_list_attr_one_slide_i" data-active={isAllowRender ? trainer_level_fix : 0} data-full="5"></li>
                                    </ul>
                                </div>
                                <TrainerAchievements achievements={isAllowRender ? achievements : []} />
                            </div>
                            {user.id+'' === trainer.id+'' ?
                                <PokemonExport battles={isAllowRender ? battles : []} trainer_id={isAllowRender ? trainer.id : '....'} /> : ''
                            }
                        </div>
                        <div className="k_mobile k_content_trainer">
                            <PokemonPokemonList pokemons={isAllowRender ? pokemons : []} />
                            <PokemonHistoryList history_list_uncorrect={isAllowRender ? battles : []} />
                            {user.id + '' === trainer.id + '' && isAllowRender ?
                                <>
                                    <PokemonExport battles={isAllowRender ? battles : []} trainer_id={isAllowRender ? trainer.id : '....'} />
                                    <PokemonPremium user={user} />
                                    <PokemonAvatarChange />
                                    <PokemonUserSettings />
                                </>: ''
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
//
export default TrainerOnePage;