import React, { useState, useEffect } from 'react';
import "./Logs.css";

import {useLogs} from "./LogsContext";
import {FormatDate1} from "../../helpers/helpers";
import $ from "jquery";

import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/ext-language_tools";

var loguru_array = false;
var loguru_array_scroll = false;
var loguru_array_scroll2 = false;
var k_global_date = 0;
var k_global_date_api = 0;
var data_text = '';
var data_text_api = '';

const LogsPage = () => {
    if (loguru_array == false){
        setInterval(function (){
            $.ajax({
                url: process.env.REACT_APP_NEW_API + '/technical_routes/logs',
                type: 'GET',
                dataType: 'json',
                data: {
                    is_ajax: 'true',
                    last_line: k_global_date,
                    last_line_api: k_global_date_api
                },
                success: function(data){
                    for (var i = 0; i < data.logs_api.length; i++) {
                        data_text_api = data.logs_api[i] + data_text_api
                    }

                    k_global_date_api = k_global_date_api + data.logs_api.length;

                    window.ace.edit("blah22").setValue(data_text_api, -1);
                    if (loguru_array_scroll2 == false){
                        window.ace.edit("blah22").renderer.scrollToLine(-Number.POSITIVE_INFINITY);
                        loguru_array_scroll2 = true;
                    }
                }
            });
        }, 6000)
        loguru_array = true;
    }

    return (
        <>
            <main className="content container" style={{padding: "0", background: "#2f3129"}}>
                <div style={{
                    width: "100%",
                    height: "98vh",
                    marginLeft: "0px",
                    float: "right"
                }}>
                    <div style={{
                        textAlign: 'center',
                        fontSize: '20px',
                        marginTop: '4px',
                        color: 'white'
                    }}>API</div>
                    <AceEditor
                        style={{
                            marginTop: "1vh",
                            width: "100%",
                            height: "93vh",
                            marginLeft: "0px"
                        }}
                        mode="plain"
                        theme="monokai"
                        value=""
                        name="blah22"
                        readOnly="true"
                        id="blah2"
                        setOptions={{ useWorker: false }}
                        fontSize={14}
                        showPrintMargin={true}
                        showGutter={true}
                        editorProps={{ $blockScrolling: true }}
                    /></div>
            </main>
        </>
    );
};

export default LogsPage;