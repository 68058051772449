import React, { createContext, useContext, useState } from 'react';
import axios from "axios";
import Cookies from 'js-cookie';
import $ from "jquery";

const TrainerOneContext = createContext(undefined);

export const TrainerOneProvider = ({ children }) => {
    const [trainer, setTrainer] = useState([]);
    const [achievements, setAchievements] = useState([]);
    const [battles, setBattles] = useState([]);
    const [pokemons, setPokemons] = useState([]);
    const [rate, setRate] = useState('');

    const load_trainer = async ({trainer_id, all_data = false}) => {

        const asyncFn = async ({trainer_id, all_data = false}) => {
            await axios.get(process.env.REACT_APP_NEW_API + '/trainers?trainer_id='+trainer_id+'&page=0', {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'trainer_token': Cookies.get('session_id')
                }
            }).then(async function (response) {
                if (response.status === 200) {
                    setTrainer(response.data.data[0]);

                    if (all_data){
                        var data = await axios.post(process.env.REACT_APP_NEW_API + '/technical_routes/single_trainer_data', JSON.stringify({ 'trainer_id': trainer_id }), {
                            headers: {
                                'Content-Type': 'application/json',
                                'trainer_token': Cookies.get('session_id')
                            }
                        })

                        console.log(data.data.battles)

                        setAchievements(data.data.achievements);
                        setBattles(data.data.battles);
                        setPokemons(data.data.pokemons);
                        setRate(data.data.rate)
                    }
                }
            }).catch(function (){
                $('header + div').html($('#k_is_all').html());
            })
        };

        await asyncFn({trainer_id, all_data});
    };

    const getAllInfo = async ({trainer_id}) => {
        return await load_trainer({trainer_id: trainer_id, all_data: true})
    }

    const value = { trainer, achievements, battles, pokemons, rate, load_trainer, getAllInfo };

    return <TrainerOneContext.Provider value={value}>{children}</TrainerOneContext.Provider>;
};

export const useTrainerOne = () => useContext(TrainerOneContext);