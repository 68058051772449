import React, { createContext, useContext, useState } from 'react';
import axios from "axios";
import Cookies from 'js-cookie';
import $ from "jquery";
import {kGetUrl} from "../../../helpers/helpers";

const ChangePasswordStep2Context = createContext(undefined);

export const ChangePasswordStep2Provider = ({ children }) => {
    const [allowChangePass, setallowChangePass] = useState(false);

    const change_password_step_2_check_hash = async () => {
        await axios.get(`${process.env.REACT_APP_NEW_API}/trainers/re?key=`+kGetUrl('password_re')+'&trainer_id='+kGetUrl('trainer_id'), {
            headers: {}
        }).then(function (response) {
            if (response.status === 200) {
                setallowChangePass(true);

                Cookies.set('password_re', kGetUrl('password_re'), { expires: 365 });
                Cookies.set('user_re_id', kGetUrl('trainer_id'), { expires: 365 });
            }
        }).catch(function (response) {
            if (response.status !== 200) {
                window.location.href = '/login';

                setallowChangePass(false);
            }
        });
    };

    const change_password_step_2 = async (values, {setErrors}) => {
        var formData = new FormData();
        formData.append('password', values['password_1']);
        formData.append('password_re', values['password_2']);
        formData.append('hash_tg', Cookies.get('password_re'));
        formData.append('id', Cookies.get('user_re_id'));
        formData.append('action', 're_new');

        await axios.post(`${process.env.REACT_APP_NEW_API}/trainers/re`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(function (response) {
            if (response.status === 200) {
                window.location.href = '/confirmation_new_password_step_2';
            }
        }).catch(function (response) {
            if (response.status !== 200) {
                var errors = [];

                errors['submit'] = response.response.data.message;

                setErrors(errors);
            }
        });
    };

    const value = { allowChangePass, change_password_step_2, change_password_step_2_check_hash };

    return <ChangePasswordStep2Context.Provider value={value}>{children}</ChangePasswordStep2Context.Provider>;
};

export const useChangePasswordStep2 = () => useContext(ChangePasswordStep2Context);