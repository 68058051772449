import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import FilterTypeSearch from "./FilterPokemons/FilterTypeSearch";
import FilterTypeBurgerMenu from "./FilterPokemons/FilterTypeBurgerMenu";
import FilterTypeSort from "./FilterPokemons/FilterTypeSort";

import FilterTypeSearchTrainers from "./FilterTrainers/FilterTypeSearch";
import FilterTypeBurgerMenuTrainers from "./FilterTrainers/FilterTypeBurgerMenu";
import FilterTypeSortTrainers from "./FilterTrainers/FilterTypeSort";

import "./FilterBody.css";

const FilterBody = ({title, type}) => {
    return (
        <div className="filter_body">
            <h1 className="style_1_heading_38_400_pokemon_classic">{title}</h1>
            {type === 'pokemons' ?
                (<div style={{'display': 'contents'}}>
                    <FilterTypeSort/>
                    <div className="right_block">
                        <FilterTypeSearch/>
                        <FilterTypeBurgerMenu/>
                    </div>
                </div>) :
                (<div style={{'display': 'contents'}}>
                    <FilterTypeSortTrainers/>
                    <div className="right_block">
                        <FilterTypeSearchTrainers/>
                        <FilterTypeBurgerMenuTrainers/>
                    </div>
                </div>)
            }

        </div>
    );
};

FilterBody.propTypes = {
    title: PropTypes.string,
};

export default FilterBody;