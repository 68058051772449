import React, { useState, useEffect } from 'react';
import "./Premium.css";

import base_arrow_left from "../../assets/icons/base_arrow_left.svg";
import base_premium_icon from "../../assets/icons/base_premium_icon.png";

import Cookies from 'js-cookie';

import {usePremium} from "./PremiumContext";
import {CheckButtonTop, DayTitle, FormatDate1, KNumberPriceFormat} from "../../helpers/helpers";
import $ from "jquery";
import {useAuth} from "../UserCore/Auth/AuthContext";
import BackButton from "../../components/Interactive/BackButton/BackButton";

$('body').on('click', '.skidka_button', function (){
    if ($(this).hasClass('active')){
        $(this).removeClass('active');
        $(this).next().slideUp();
    }else{
        $(this).addClass('active');
        $(this).next().slideDown();
    }
})

function formula_day_price(days, format=true){
    var skidka = 0;
    var price_start = +days;

    if (price_start<=0){
        $('.k_skidka_premium').fadeOut();
    }
    if (0 < price_start && price_start < 30){
        skidka = 100;
        $('.k_skidka_premium').fadeIn();
        $('.k_skidka_premium').html('по 100 ₽/день');
    }
    if (30 <= price_start && price_start < 180){
        skidka = 95;
        $('.k_skidka_premium').fadeIn();
        $('.k_skidka_premium').html('по 95 ₽/день');
    }
    if (180 <= price_start && price_start < 365){
        skidka = 90;
        $('.k_skidka_premium').fadeIn();
        $('.k_skidka_premium').html('по 90 ₽/день');
    }
    if (365 <= price_start){
        skidka = 85;
        $('.k_skidka_premium').fadeIn();
        $('.k_skidka_premium').html('по 85 ₽/день');
    }

    var price = price_start * skidka;

    if (price > 0){
        $('.k_buy_premium').addClass('active');
    }else{
        $('.k_buy_premium').removeClass('active');
    }

    if (format){
        price = KNumberPriceFormat(price);
    }

    return price
}

$('body').on('copy paste', '.k_input_days', function (e) {
    e.preventDefault();
});

$('body').on('change keyup', '.k_input_days', function (){
    if (+$(this).val() > 999){
        $(this).val(999);
    }

    $('.k_price_premium span').html(formula_day_price($(this).val()));
    $('.k_form_reg_mail_error').fadeOut();
})

$('body').on('click', '.k_cansel_back_premium', function (){
    $('.k_page_cansel_premium').css('display', 'none');
    $('.k_page_main_premium').css('display', 'block');

    CheckButtonTop('k_page_main_premium .button_to_down', 'profile-mobile-wrapper', 56);
})

$('body').on('click', '.k_cansel_premium', function (){
    $('.k_page_cansel_premium').css('display', 'block');
    $('.k_page_main_premium').css('display', 'none');
})

$('body').on('click', '.k_ok_battles_premium', function (){
    window.location.href = '/'
})

$('body').on('click', '.k_re_subscribe_back_premium', function (){
    $('.k_page_cansel_ok_premium').css('display', 'none');
    $('.k_page_main_premium').css('display', 'block');

    $('.k_active_premium').css('display', 'none');
    $('.k_no_premium').css('display', 'block');
    $('.button_cansel_premium').css('display', 'none');

    $('.k_fix_profile_go').css('visibility', 'visible');

    CheckButtonTop('k_page_main_premium .button_to_down', 'profile-mobile-wrapper', 56);
})

$('body').on('click', '.k_cansel_go_premium', function (){
    $.ajax({
        url: process.env.REACT_APP_LAVKA_API + 'cancel_premium',
        type: 'POST',
        dataType: 'json',
        headers: {'trainer_token': Cookies.get('session_id')},
        contentType: 'application/json',
        data: {},
        async: false,
        success: function (data) {
            $('.k_page_cansel_premium').css('display', 'none');
            $('.k_page_cansel_ok_premium').css('display', 'block');
            $('.trainer_info_l_fix').remove();

            Cookies.set('is_premium', false, { expires: 365 });
            Cookies.set('premium_duration', false, { expires: 365 });
        }
    });
})

$('body').on('click', '.k_buy_premium', function (){
    if ($('.k_input_days').val() > 0) {
        Cookies.set('order_type', 'premium', {expires: 365});
        Cookies.set('premium_price', formula_day_price($('.k_input_days').val(), false), {expires: 365});
        Cookies.set('premium_day', $('.k_input_days').val(), {expires: 365});

        window.location.href = '/payment/0';
    }else{
        $('.k_form_reg_mail_error').fadeIn();
    }

    return false;
})

$(window).on('resize', function (){
    CheckButtonTop('k_page_main_premium .button_to_down', 'profile-mobile-wrapper', 56);
})

$('body').on('click', '.k_fix_profile_go', function (){
    window.location.href = '/trainer/'+Cookies.get('user_id')
})

const PremiumPage = () => {
    const { user } = useAuth();
    const { rating, lastUpdate, load } = usePremium();

    React.useEffect(() => {
        load()
    }, []);

    setTimeout(function (){
        if ($('.k_input_days').val() !== ''){
            $('.k_price_premium span').html(formula_day_price($('.k_input_days').val()));
        }
    }, 500);

    if (Cookies.get('premium_success') === 'yes'){
        Cookies.set('premium_success', 'no', {expires: 365});

        setTimeout(function (){
            $('.k_page_ok_premium').css('display', 'block');
            $('.k_page_main_premium').css('display', 'none');
        }, 500)
    }

    const [toggle, setToggle] = useState(true);
    const handleChange = (event) => {
        const input = event.target;
        const icon = input.closest('.auth__wrap').querySelector('.auth__input-icon');
        icon.style.display='block';
        if(input.value.length == 0) {
            icon.style.display='none';
        }
    }

    const handleClick = (event) => {
        const icon = event.target;
        const input = icon.closest('.auth__wrap').querySelector('.auth__input-pass');
        if(toggle) {
            input.type = 'text';
            icon.classList.add('view-pass');
        }  else {
            input.type = 'password';
            icon.classList.remove('view-pass');
        }
        setToggle(!toggle);
    }

    setTimeout(function (){
        $('.k_input_days')[0].addEventListener("keypress", e => {
            const keyValue = e.key;

            if (!e.target.value && keyValue == 0) {
                e.preventDefault();
            } else {
                if (!/[0-9]/.test(keyValue)) {
                    e.preventDefault();
                } else {
                    if (/[0-9]{1,2}[]/.test(e.target.value) && keyValue == ".") {
                        e.preventDefault();
                    } else if (/[0-9]{1,2}[]/.test(e.target.value)) {
                        if (/[0-9]{1,2}[][0-9]{2}/.test(e.target.value)) {
                            e.preventDefault();
                        }
                    }

                    else if (/[0-9]{3}/.test(e.target.value) && keyValue != ".") {

                        e.target.value += ".";

                        if (/[0-9]{3}[.][0-9]{2}/.test(e.target.value)) {
                            e.preventDefault();
                        }
                    }
                }
            }
        });

        CheckButtonTop('k_page_main_premium .button_to_down', 'profile-mobile-wrapper', 56);
    }, 500);

    if (Cookies.get('is_premium') === 'true'){
        $('.trainer_info_l_fix').attr('data-premium', true);

        $('.history-info-duration').css('display', 'flex');
        $('.k_active_premium').css('display', 'block');
        $('.button_cansel_premium').css('display', 'block');
        $('.history-info-duration-num').html(Cookies.get('premium_duration'));
        $('.history-info-duration-day').html(DayTitle(+Cookies.get('premium_duration')));
    }else{
        $('.k_no_premium').css('display', 'block');
        CheckButtonTop('k_page_main_premium .button_to_down', 'profile-mobile-wrapper', 56);
    }

    return (
        <div className="premium_page">
            <main className="content">
                <div className="gradient auth_gradient"></div>
                <BackButton />
                <section className="auth profile premium_fix_top">
                    <div className="login__content profile k_page_ok_premium" style={{'display': 'none'}}>
                        <div className="profile-mobile">
                            <div className="profile__back-button-mobile k_fix_profile_go">
                                <img src={base_arrow_left} alt="вернуться назад" />
                            </div>
                            <h2 className="profile__title-mobile">Pokemon Premium</h2>
                        </div>

                        <div className="profile-mobile-wrapper">
                            <h1 className="profile__title">Pokemon Premium</h1>

                            <div className="k_active_premium">
                                <img src={base_premium_icon}/>
                                <div className="k_title_premium">Премиум успешно подключен!</div>
                                <div className="k_txt_premium">Осталось дней: <div className="history-info-duration-num"></div></div>
                            </div>
                            <div className="button_to_down">
                                <div>
                                    <button type="submit" className="profile__button profile__button-right k_ok_battles_premium">
                                        К битвам
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="login__content profile k_page_cansel_ok_premium" style={{'display': 'none'}}>
                        <div className="profile-mobile">
                            <div className="profile__back-button-mobile k_fix_profile_go">
                                <img src={base_arrow_left} alt="вернуться назад" />
                            </div>
                            <h2 className="profile__title-mobile">Pokemon Premium</h2>
                        </div>
                        <div className="profile-mobile-wrapper">
                            <h1 className="profile__title">Pokemon Premium</h1>

                            <div className="k_pre_title_premium k_big_font_premium">Вы отменили подписку :(</div>
                            <div className="k_txt_premium k_fix">Если передумаете, её можно оформить в профиле вашего тренера</div>

                            <div className="button_to_down">
                                <div>
                                    <button type="submit" className="profile__button profile__button-right k_re_subscribe_back_premium">
                                        Возобновить подписку
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="login__content profile k_page_cansel_premium" style={{'display': 'none'}}>
                        <div className="profile-mobile">
                            <div className="profile__back-button-mobile k_fix_profile_go">
                                <img src={base_arrow_left} alt="вернуться назад" />
                            </div>
                            <h2 className="profile__title-mobile">Pokemon Premium</h2>
                        </div>
                        <div className="profile-mobile-wrapper">
                            <h1 className="profile__title">Pokemon Premium</h1>

                            <div className="k_pre_title_premium k_big_font_premium">Вы уверены, что хотите отменить подписку?</div>

                            <div className="button_to_down">
                                <div>
                                    <button type="submit" className="profile__button profile__button-right k_cansel_back_premium">
                                        Оставить подписку
                                    </button>
                                </div>

                                <div>
                                    <button type="submit" className="profile__button profile__button-right k_cansel_go_premium">
                                        Отменить подписку
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="login__content profile k_page_main_premium">
                        <div className="profile-mobile">
                            <div className="profile__back-button-mobile k_fix_profile_go">
                                <img src={base_arrow_left} alt="вернуться назад" />
                            </div>
                            <h2 className="profile__title-mobile">Pokemon Premium</h2>
                        </div>
                        <div className="profile-mobile-wrapper">
                            <h1 className="profile__title">Pokemon Premium</h1>

                            <div className="k_active_premium" style={{'display': 'none'}}>
                                <img src={base_premium_icon}/>
                                <div className="k_title_premium">Премиум подключен!</div>
                                <div className="k_txt_premium">Осталось дней: <div className="history-info-duration-num"></div></div>
                            </div>

                            <div className="k_pre_title_premium style_1_text_left">Что даёт подписка</div>
                            <div className="k_info_premium style_1_text_left">
                                <div className="k_info_line_premium">
                                    <img src={base_premium_icon} width="16" height="16" alt="Премиум"/>
                                    <span>65 битв в день вместо 50 — становитесь чемпионом быстрее</span>
                                </div>
                                <div className="k_info_line_premium">
                                    <img src={base_premium_icon} width="16" height="16" alt="Премиум"/>
                                    <span>Эксклюзивный значок премиум-тренера</span>
                                </div>
                            </div>

                            <div className="button_to_down button_cansel_premium" style={{'display': 'none'}}>
                                <button type="submit" className="profile__button profile__button-right k_cansel_premium">
                                    Отменить подписку
                                </button>
                            </div>

                            <div className="k_no_premium" style={{'display': 'none'}}>
                                <div className="k_pre_title_premium">Стоимость</div>
                                <div className="k_list_b_premium">
                                    <ul>
                                        <li>до 30 дней — 100 ₽/день</li>
                                        <li>от 30 до 180 дней — 95 ₽/день</li>
                                        <li>от 180 до 365 дней — 90 ₽/день</li>
                                        <li>365 и более — 85 ₽/день</li>
                                    </ul>
                                </div>

                                <div className="k_pre_title_premium k_pre_title_premium_fix">Оформление подписки</div>

                                <form action="#" className="auth__form profile__email-f profile__container k_profile_email_register" noValidate={true} style={{'padding-bottom': '0px'}}>
                                    <div className="auth__wrap k_input_premium">
                                        <input type="number" className="auth__input profile__input k_input_days" max="999" min="1" placeholder=" "/>
                                        <label className="auth__label">Количество дней</label>
                                        <span className="k_skidka_premium" style={{ display: "none" }}>- 10%</span>
                                        <span className="auth__error k_form_reg_mail_error" style={{ display: "none" }}>Введите количество дней!</span>
                                    </div>
                                    <span className="k_input_premium_desc">Минимум 1 день, максимум 999 дней</span>

                                    <div className="k_flex_premium">
                                        <div className="k_pre_title_premium" style={{'margin-bottom': '0px'}}>К оплате</div>
                                        <div className="k_price_premium"><span>0</span> ₽</div>
                                    </div>

                                    <div className="button_to_down">
                                        <button type="submit" className="profile__button profile__button-right k_buy_premium">
                                            Перейти к оплате
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    );
};

export default PremiumPage;