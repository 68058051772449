import React, { useState, useEffect } from 'react';
import PokemonsListCard from "../../components/Pokemons/PokemonsList/PokemonsListCard";
import axios from "axios";
import FilterTypeBurgerMenu from "../../components/FilterPokemonsAndTrainers/FilterPokemons/FilterTypeBurgerMenu";
import {usePokemonList} from "./PokemonListContext";
import none_pokemon from "../../assets/images/none_pokemon.png"
import "./PokemonList.css"
import $ from "jquery";

const PokemonListPage = (sort_info) => {
    const { nextPage, filteredPokemons, load, sort_new } = usePokemonList();

    React.useEffect(() => {
        load()
    }, []);

    const handleLoadMore = async () => {
        await sort_new({load_more:true})
    };

    return (
        <div>
            <div className="pokemon_list">
                <div className="gradient"></div>
                {filteredPokemons ? filteredPokemons.map((pokemon) => (
                    <PokemonsListCard pokemon={pokemon} />
                )) :
                    <div className="empty">
                        <img className="empty__img" src={none_pokemon} alt="картинка"/>
                        <h2 className="empty__title">Никого не найдено</h2>
                        <p className="empty__subtitle">Попробуйте изменить параметры запроса</p>
                    </div>
                }
            </div>
            {nextPage ?
                <div className="k_load_more">
                    <button className="style_1_caption_16_500 style_1_button_background" onClick={handleLoadMore}>Загрузить еще</button>
                </div> : ''}
        </div>
    );
};

export default PokemonListPage;