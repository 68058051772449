import React from 'react';

import './PaymentFormCard.css';
import $ from "jquery";
import {NavLink} from "react-router-dom";

import InputMask from 'react-input-mask';
import { useForm } from "react-hook-form";

import Cookies from 'js-cookie';

import base_arrow_left_black from "../../../assets/icons/base_arrow_left_black.svg";

import mir from '../../../assets/images/payment/mir.png';
import visa from '../../../assets/images/payment/visa.png';
import master from '../../../assets/images/payment/master.png';
import def from '../../../assets/images/payment/defaultcard.png';

import {CardAppendAt, CardReplaceAt, IsNumber} from "../../../helpers/helpers";

const monthsSince1900 = string => {
    let chars = string.split('');
    let month = +chars.slice(0, -2).join('');
    let year = +chars.slice(-2).join('');
    return 12 * (100 + year) + month
};

const currentMonthsSince1990 = () => {
    let d = new Date();
    return 12 * d.getYear() + d.getMonth();
}

const isExpiryValid = string => {
    if (string.indexOf(' ') !== -1){
        return false;
    }

    let now = currentMonthsSince1990()
    let exp = monthsSince1900(string);

    let chars = string.split('');
    let year = +chars.slice(-2).join('');
    if (year > 35){
        return false;
    }

    if (+chars.slice(-4, -2).join('') <= 0){
        return false;
    }

    return exp > now
}

function k_check_all_field(){
    var error = 0;
    var val_date = $(".card_date").val();
    if (val_date.replace('/', '').length >= 4){
        if (isExpiryValid(val_date.replace('/', '')) && +(val_date.replace('/', '')[0]+''+val_date.replace('/', '')[1])<=12 ){}else{
            error = 1;
        }
    }else{
        error = 1;
    }

    if ( $(".card_csv").val() === '' || $(".card_csv").val().length < 3){
        error = 1;
    }

    if ( $(".card_name").val() === '' || $(".card_name").val().length < 1){
        error = 1;
    }

    return error;
}

function k_send_luna(){
    $.ajax({
        url: process.env.REACT_APP_LAVKA_API + 'luhn_check',
        type: 'POST',
        dataType: 'json',
        data: JSON.stringify({card_number: $(".card_number").val().replaceAll(' ', '')}),
        contentType: 'application/json',
        success: function(data){
            $(".card_number").next().fadeOut();
            $(".card_number").removeClass('k_f_error');
            if (k_check_all_field() !== 1){
                $(".style_1_base_button_payment").removeClass('disable');

            }else{
                $(".style_1_base_button_payment").addClass('disable');
            }

            is_valid_card = true;
        },
        error: function (data){
            $(".card_number").next().fadeIn();
            $(".style_1_base_button_payment").addClass('disable');
            is_valid_card = false;
            $(".card_number").addClass('k_f_error');
        }
    });
}

$('body').on('keydown keyup change', ".card_date", function (){
    console.log($(this).val())

    var val_date = $(this).val();
    if (val_date.replaceAll(' ', '').replace('/', '').length >= 1){
        if (isExpiryValid(val_date.replace('/', '')) && +(val_date.replace('/', '')[0]+''+val_date.replace('/', '')[1])<=12 ){
            $(".card_date").next().fadeOut();
            $(".card_date").removeClass('k_f_error');
        }else{
            $(".card_date").next().fadeIn();
            $(".card_date").addClass('k_f_error');
        }
    }else{
        $(".card_date").next().fadeOut();
        $(".card_date").removeClass('k_f_error');

        $(".style_1_base_button_payment").addClass('disable');
    }

    if ($(".card_number").val().replace(' ', '').length){
        k_send_luna();
    }else{
        k_check_all_field();
    }
})

$('body').off('keyup change', ".card_name, .card_ccv");
$('body').on('keyup change', ".card_name, .card_ccv", function (){
    if (!$(".card_number").hasClass('k_f_error')){
        if (k_check_all_field() !== 1){
            if (is_valid_card){
                $(".style_1_base_button_payment").removeClass('disable');
            }
        }else{
            $(".style_1_base_button_payment").addClass('disable');
        }
    }
});

$('body').off('change', ".card_name, .card_ccv");
$('body').on('change', ".card_name, .card_ccv", function (){
    if ($(".card_number").val().replace(' ', '').length){
        k_send_luna();
    }
});

/* НАЧАЛО ЛОГИКИ ПОЛЯ НОМЕРА КАРТЫ */
function render_number_card(number, last_row_three=true, is_calculate=false, get_fix_selection=false){
    var len_row=4;
    var num_rows=4;
    var len_max=19;

    var i_rows = 1;
    var i_rows_create = 0;
    var format_number = '';

    if (number[0] !== '2'){
        len_max = 16;
        last_row_three = false;
    }

    for (var i=0; i<len_max; i++){

        if (number[i]){
            format_number += number[i];
        }else{
            break;
        }

        if (i_rows >= len_row && (i_rows_create < (num_rows-1) || i_rows_create < num_rows && last_row_three === true)){
            if (i === 15 && number.length === 16){}else{
                format_number += ' ';
            }

            i_rows_create++;
            i_rows = 0;

            if (get_fix_selection && get_fix_selection-1 < format_number){
                get_fix_selection++;
            }
        }

        i_rows++;
    }

    if (get_fix_selection){
        return get_fix_selection;
    }

    return format_number;
}

function formate_card_number(number, selection){
    var len_row=4;
    var num_rows=4;
    var len_max=23;

    var i_rows = 1;
    var i_rows_create = 0;
    var format_number = '';

    var last_row_three = true;
    if (number[0] !== '2'){
        len_max = 19;
        last_row_three = false;
    }

    for (var i=0; i<len_max; i++){
        if (number[i]){
            format_number += number[i];
        }else{
            break;
        }

        if (i_rows > len_row && (i_rows_create < (num_rows-1) || i_rows_create < num_rows && last_row_three === true)){
            if (number[i] === ' '){
                i_rows = 0;
            }else{
                number = CardAppendAt(number, i, ' ');

                i_rows_create++;
                i_rows = 0;

                selection++;
            }
        }

        i_rows++;
    }

    return selection;
}

function check_type_mask_number_card(new_value, is_selection = -1){
    if (is_selection !== -1){
        return new_value.length >= 23 && new_value[0] === '2' && is_selection >= 23 || new_value.length >= 19 && new_value[0] !== '2' && is_selection >= 19;
    }else{
        return new_value.length < 23 && new_value[0] === '2' || new_value.length < 19 && new_value[0] !== '2';
    }
}

function recalculate_field(card, new_selection, new_value){
    if (new_selection > 23 && new_value[0] === '2'){
        new_selection = 23;
    }

    if (new_selection > 19 && new_value[0] !== '2'){
        new_selection = 19;
    }

    if (new_selection === 20 && new_value[0] === '2'){
        new_selection++;
    }

    if (new_selection < 0){
        new_selection = 0;
    }

    var only_numbers = render_number_card(new_value.replace(/[^0-9]/g,""), true, true);
    if (new_selection > only_numbers.length){
        new_selection = only_numbers.length;
    }

    var render_value = render_number_card(new_value.replace(/[^0-9]/g,""), true, false);

    card.val(render_value);
    card.attr('fix-val', render_value)

    card[0].selectionEnd = card[0].selectionStart = new_selection;

    /* иконки карты */
    if (only_numbers.length > 0){
        var val_card = card.val().replace(' ', '').replace(/\s/g, "").length;

        k_send_luna();
        if (val_card >= 18){
            k_send_luna();
        }else{
            $(".style_1_base_button_payment").addClass('disable');
        }

        if (val_card <= 0) {
            $('img.payment_form_card_form_input_img').fadeOut();
        }else{
            $('img.payment_form_card_form_input_img').fadeIn();
            //54645

            if (card.val()[0] === '2'){
                $('img.payment_form_card_form_input_img').attr('src', mir);
                return false;
            }
            if (card.val()[0] === '4'){
                $('img.payment_form_card_form_input_img').attr('src', visa);
                return false;
            }
            if (card.val()[0] === '5'){
                $('img.payment_form_card_form_input_img').attr('src', master);
                return false;
            }

            $('img.payment_form_card_form_input_img').attr('src', def);
        }
    }else{
        $(".card_number").next().fadeOut();
        $(".card_number").removeClass('k_f_error');

        $('img.payment_form_card_form_input_img').fadeOut();
        $(".style_1_base_button_payment").addClass('disable');
    }
}

$('body').off('focus', ".card_number");
$('body').on('focus', ".card_number", function (){
    var card = $(".card_number");

    if (card.val() === ''){
        recalculate_field(card, 0, '');
    }
})

$('body').off('paste copy cut drop', ".card_number");
$('body').on('paste copy cut drop', ".card_number", function (){
    var e = e || window.event

    var card = $(".card_number");
    var new_val = card.val();
    var cursor_start = card[0].selectionStart;
    var cursor_end = card[0].selectionEnd;

    if (e.type === 'copy'){
        return true;
    }

    if (e.type === 'drop'){
        setTimeout(function (card, new_cursor){
            recalculate_field(card, new_cursor, $(".card_number").val())
        }, 100, card, new_cursor);
        return true;
    }

    var add_text = e.clipboardData.getData('Text').trim();
    var new_cursor = cursor_start;
    var i_t = 0;
    if (e.type === 'cut'){
        for (i_t=0; i_t<(cursor_end - cursor_start); i_t++){
            if (card.val()[cursor_start+i_t] === ' '){
                new_cursor++;
            }

            new_val = CardReplaceAt(new_val, cursor_start, '');
            add_text += card.val()[cursor_start+i_t];
        }

        new_cursor += i_t;
        if( !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
            navigator.clipboard.writeText(add_text);
        }else{
            setTimeout(function (card, new_cursor, new_val){
                recalculate_field(card, new_cursor, new_val)
            }, 100, card, new_cursor, new_val);
            return true;
        }

    }else{
        if (check_type_mask_number_card(card.val(), cursor_end - cursor_start)){
            new_val = '';
        }

        if (check_type_mask_number_card(card.val())) {
            if (cursor_end !== cursor_start) {
                for (var i_c = cursor_start; i_c <= cursor_end; i_c++) {
                    new_val = CardReplaceAt(new_val, (cursor_start), '');
                }
            }
        }

        for (i_t=0; i_t<add_text.length; i_t++){
            if (new_val[cursor_start+i_t] === ' '){
                if (add_text[i_t] !== ' '){
                    add_text = CardAppendAt(add_text, i_t, ' ');
                }
            }

            if (check_type_mask_number_card(new_val)) {
                new_val = CardAppendAt(new_val, (cursor_start + (i_t)), add_text[i_t]);
            }else{
                new_val = CardReplaceAt(new_val, (cursor_start + (i_t)), add_text[i_t]);
            }
        }

        new_cursor += i_t;
        new_cursor = formate_card_number(new_val, new_cursor);
    }

    recalculate_field(card, new_cursor, new_val)
    return false
});

function construct_card_key(key, card=$(".card_number"), cursor_start=card[0].selectionStart, cursor_end=card[0].selectionEnd){
    card.val(card.attr('fix-val'));

    var new_val = card.val();
    if (check_type_mask_number_card(card.val(), cursor_end - cursor_start)){
        new_val = '';
    }

    if (check_type_mask_number_card(card.val())) {
        if (cursor_end !== cursor_start) {
            for (var i_c = cursor_start; i_c <= cursor_end; i_c++) {
                new_val = CardReplaceAt(new_val, (cursor_start), '');
            }
        }
    }

    var only_numbers = render_number_card(card.val().replace(/[^0-9]/g,""), true, true);

    if (only_numbers[cursor_start] === ' '){
        cursor_start += 1;
    }

    if (cursor_start > only_numbers.length){
        new_val = CardReplaceAt(new_val, only_numbers.length, key);
        card.change();
        cursor_start = only_numbers.length+1;
    }else if (cursor_start+1 === only_numbers.length){
        new_val = CardReplaceAt(new_val, cursor_start, key);
        cursor_start = only_numbers.length;
    }else{
        if (check_type_mask_number_card(card.val())){
            new_val = CardAppendAt(new_val, cursor_start, key);
        }else{
            new_val = CardReplaceAt(new_val, cursor_start, key);
        }

        cursor_start = cursor_start+1;
    }

    recalculate_field(card, cursor_start, new_val)
}

$('body').off('keyup', ".card_number");
$('body').on('keyup', ".card_number", function (e){
    if (e.key === 'Unidentified') {
        var key = e.target.value.charAt(e.target.selectionStart - 1).charAt();

        var card = $(".card_number");
        var new_val = card.val();

        var cursor_start = card[0].selectionStart;
        var cursor_end = card[0].selectionEnd;
        var create_new_char = true;

        var start_l = card.attr('fix-val').replace(/[^0-9]/g,"").length;
        var end_l = card.val().replace(/[^0-9]/g,"").length;
        if (start_l < end_l && (check_type_mask_number_card(card.attr('fix-val')))){
            cursor_start = render_number_card(card.val().replace(/[^0-9]/g,""), true, true, cursor_start);

            if (cursor_start < card.val().length){
                if (card.val()[cursor_start-1] === ' '){
                    cursor_start++;
                }

                cursor_start--;
            }

            recalculate_field(card, cursor_start, new_val)
            return false;
        }else{
            //alert(card.attr('fix-val')+' ---- '+card.val());

            // Если заполнена вся маска

            // Сравниваем текущее значение поля с конечным, если есть разница, смещаем каретку назад
            if (start_l < end_l){
                cursor_start-= (end_l-start_l);
            }

            // Если есть выделение
            if (cursor_end > cursor_start){
                var currect_cursor = cursor_start;
                cursor_start += 1;

                if (new_val[cursor_start] === ' '){
                    currect_cursor++;
                    cursor_start++;
                    cursor_end++;
                }

                for (var i_d=0; i_d<(cursor_end-currect_cursor); i_d++){
                    if (new_val[currect_cursor+(cursor_end-currect_cursor)] === ' '){
                        new_val = CardReplaceAt(new_val, currect_cursor+(cursor_end-currect_cursor), '');
                    }

                    new_val = CardReplaceAt(new_val, currect_cursor+(cursor_end-currect_cursor), '');
                }

                card.val(new_val)

                if (card.attr('fix-val')[cursor_start] === ' '){
                    cursor_start++;
                }

                cursor_start += (cursor_end-currect_cursor);
                create_new_char = false;
            }else{
                create_new_char = false;
                cursor_start++;
            }
        }

        recalculate_field(card, cursor_start-1, new_val)

        if (create_new_char){
            construct_card_key(key);
        }

    }

    return false;
});

$('body').off('keydown', ".card_number");
$('body').on('keydown', ".card_number", function (e){
    var key = '';

    if (e.key === 'Unidentified'){
        return false;
    }else{
        key = e.key;
    }

    var new_val = '';
    var card = $(".card_number");

    if (!card.attr('fix-val')){
        card.attr('fix-val', card.val());
    }

    var cursor_start = card[0].selectionStart;
    var cursor_end = card[0].selectionEnd;

    if (e.ctrlKey || e.metaKey){
        return true;
    }

    if (key === 'Backspace' || key === 'ArrowRight' || key === 'ArrowLeft'){
        if (key === 'Backspace'){
            if (cursor_end > cursor_start){
                new_val = card.val();
                for (var i_d=0; i_d<cursor_end-cursor_start; i_d++){
                    new_val = CardReplaceAt(new_val, (cursor_end-(i_d))-1, '');
                }

                cursor_start++;
            }else{
                new_val = CardReplaceAt(card.val(), cursor_start-1, '');
            }

            recalculate_field(card, cursor_start-1, new_val)
        }

        if (key === 'ArrowLeft' && cursor_start > 0){
            recalculate_field(card, cursor_start-1, card.val())
        }

        if (key === 'ArrowRight' && (cursor_start < 23 && card.val()[0] === '2' || cursor_start < 19 && card.val()[0] !== '2')){
            recalculate_field(card, cursor_start+1, card.val())
        }

        return false;
    }

    if (!IsNumber(key)){
        return false;
    }

    construct_card_key(key, card, cursor_start);

    return false;
})

/* КОНЕЦ ЛОГИКИ ПОЛЯ НОМЕРА КАРТЫ */



var is_valid_card = false;
const PaymentFormCard = ({price}) => {
    const [receiptOpen, SetReceiptOpen] = React.useState(false);

    const handleReceiptOpen = () => {
        SetReceiptOpen(!receiptOpen);

        if (!receiptOpen){
            $('.payment_receipt_open_block').slideDown();
        }else{
            $('.payment_receipt_open_block').slideUp();
        }

    };

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isValid },
    } = useForm({
        mode: "all",
    });

    var is_valid_non_read = is_valid_card;

    const onSubmit = () => {
        if($('.style_1_base_button_payment').hasClass('disable')){
            return false;
        }

        var isValid_k = true;
        if ( $(".card_csv").val() != '125' && $(".card_csv").val() != '300'){
            $(".card_csv").next().fadeIn();
            isValid_k = false;
        }

        if (k_check_all_field() !== 1 && isValid_k) {
            var credit_n = $(".card_number").val().replaceAll(' ', '').replaceAll('_', '');


            Cookies.set('user_buy_product', window.location.href.split('/')[ window.location.href.split('/').length - 1 ], { expires: 365 });
            Cookies.set('user_buy_card', '*'+credit_n.substr(credit_n.length - 4), { expires: 365 });
            Cookies.set('user_buy_card_full', '*'+$(".card_number").val(), { expires: 365 });
            Cookies.set('user_buy_price', price, { expires: 365 });
            Cookies.set('user_buy_code', $(".card_csv").val(), { expires: 365 });
            Cookies.set('user_buy_name', $(".card_name").val(), { expires: 365 });
            Cookies.set('user_buy_date', $(".card_date").val(), { expires: 365 });
            window.location.href = '/payment_3ds';
            reset();
        }
    }

    if (is_valid_non_read){
        if (k_check_all_field() === 1){
            is_valid_non_read = false;
        }
    }

    return (
        <form className="payment_form_card" onSubmit={handleSubmit(onSubmit)}>
            <div className="payment_form_card_form">
                <h3 className="payment_form_card_form_title style_1_caption_18_500">Карта</h3>
                <div className="payment_form_card_form_input">
                    <label className="payment_form_card_form_input_label style_1_caption_12_500">Номер</label>
                    <img alt='card bank' className="payment_form_card_form_input_img" style={{display:'none'}} />

                    <InputMask
                        placeholder="0000 0000 0000 0000"
                        className='style_1_base_input card_number'
                    />
                    <span className="style_1_base_input_error" style={{display:'none'}}>Неверный номер карты</span>
                </div>

                <div className="payment_form_card_form_inputs">
                    <div className="payment_form_card_form_input">
                        <label className="payment_form_card_form_input_label style_1_caption_12_500">Срок</label>
                        <InputMask
                            maskChar=""
                            mask="99/99"
                            placeholder="00/00"
                            className='style_1_base_input card_date'
                            aria-invalid={errors.date ? "true" : "false"}
                        />
                        <span className="style_1_base_input_error" style={{display:'none'}}>Неверный срок</span>
                    </div>

                    <div className="payment_form_card_form_input">
                        <label className="payment_form_card_form_input_label style_1_caption_12_500">Код</label>
                        <InputMask
                            mask="999"
                            maskChar=""
                            placeholder="000"
                            aria-invalid={errors.csv ? "true" : "false"}
                            className='style_1_base_input card_csv'/>
                        <span className="style_1_base_input_error" style={{display:'none'}}>Неверный код</span>
                    </div>
                </div>
                <div className="payment_form_card_form_input payment_form_card_form_input_last">
                    <label className="payment_form_card_form_input_label style_1_caption_12_500">Имя</label>
                    <input className='style_1_base_input card_name'
                           aria-invalid={errors.name ? "true" : "false"}
                           placeholder="GERMAN DOLNIKOV"
                    />
                    <span className="style_1_base_input_error" style={{display:'none'}}>Неверное имя</span>
                </div>
                <div className="style_1_base_button_payment_body">
                    <button type='submit' className={'style_1_base_button_payment' + (is_valid_non_read ? " allow" : ' disable')}>Оплатить</button>
                </div>
            </div>
        </form>
    );
};

export default PaymentFormCard;