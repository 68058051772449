import React from 'react';
import PropTypes from 'prop-types';

import './LinkStandart.css';
import {Link} from "react-router-dom";

const LinkStandart = ({link, title, type=""}) => {
    return (
        <Link to={link} className={"style_1_popup_white_link_standart style_1_caption_14_500 base_link " + type}>
            {title}
        </Link>
    );
};

LinkStandart.propTypes = {
    link: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string
};

export default LinkStandart;