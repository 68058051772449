import React from 'react';
import './Footer.css';

import base_healtcheck from "../../../assets/images/base_healtcheck.png"
import base_qa_studio from "../../../assets/images/base_qa_studio.png"
import Cookies from "js-cookie";

const Footer = ({is_control=''}) => {
    return (
        <footer className={"footer "+is_control}>
            <div className="footer_container">
                <div className="footer_logo">
                    <a className="status_url" href="https://status.pokemonbattle.ru" target="_blank">
                        <img src={base_healtcheck} alt="статус" />
                    </a>
                    <div className="k_footer_container_version style_1_caption_16_500">{Cookies.get('react_version')}</div>
                    <a href="https://qa.studio/" target="_blank">
                        <img className="footer_studio" src={base_qa_studio} alt="логотип" />
                    </a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;