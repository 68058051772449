import React, { createContext, useContext, useState } from 'react';
import axios from "axios";
import Cookies from 'js-cookie';
import $ from "jquery";
import {useTrainerOne} from "../TrainerOne/TrainerOneContext";

const PokemonOneContext = createContext(undefined);

export const PokemonOneProvider = ({ children }) => {
    const [pokemon, setPokemon] = useState([]);
    const [pokemonHistory, setPokemonHistory] = useState([]);
    const { trainer, load_trainer } = useTrainerOne();

    const load = async () => {
        const asyncFn = async () => {
            var pokemon_id = window.location.pathname.split('/');
            if (pokemon_id[pokemon_id.length - 1]){
                pokemon_id = pokemon_id[pokemon_id.length - 1];
            }else{
                pokemon_id = pokemon_id[pokemon_id.length - 2];
            }

            return await axios.get(process.env.REACT_APP_NEW_API + '/pokemons?pokemon_id='+pokemon_id+'&page=0', {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'trainer_token': Cookies.get('session_id')
                }
            }).then(function (response) {
                if (response.status === 200) {
                    setPokemon(response.data.data[0]);
                    load_trainer({trainer_id: response.data.data[0].trainer_id})
                    loadHistory({pokemon_id: response.data.data[0].id})
                }
            }).catch(function (){
                $('header + div').html($('#k_is_all').html());
            })
        };

        return await asyncFn();
    };

    const loadHistory = async ({pokemon_id}) => {
        const asyncFn = async ({pokemon_id}) => {
            return await axios.get(process.env.REACT_APP_NEW_API + '/battle?pokemon_id='+pokemon_id, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'trainer_token': Cookies.get('session_id')
                }
            }).then(async function (response) {
                if (response.status === 200) {

                    var data_battle = response.data.data;

                    if (data_battle) {
                        data_battle = data_battle.reverse();
                        var data_sort = [];
                        for (var i = 0; i < data_battle.length; i++) {
                            if (data_battle[i].loser === pokemon_id) {
                                var my = {'id': data_battle[i].loser, 'attack': Math.round(data_battle[i].loser_attack)};
                                var enemy = {'id': data_battle[i].winner, 'attack': Math.round(data_battle[i].winner_attack)};
                            } else {
                                var my = {'id': data_battle[i].winner, 'attack': Math.round(data_battle[i].winner_attack)};
                                var enemy = {'id': data_battle[i].loser, 'attack': Math.round(data_battle[i].loser_attack)};
                            }

                            var data = await axios.get(process.env.REACT_APP_NEW_API + '/pokemons?pokemon_id=' + enemy.id + '&page=0', {
                                headers: {
                                    'Content-Type': 'multipart/form-data',
                                    'trainer_token': Cookies.get('session_id')
                                }
                            })

                            enemy.add = data.data.data[0];

                            data_sort[i] = {'my': my, 'enemy': enemy, 'add': data_battle[i]}
                        }

                        console.log(data_sort);

                        setPokemonHistory(data_sort);
                    }
                }
            })
        };

        return await asyncFn({pokemon_id});
    };

    const getAllInfo = async () => {
        return await load()
    }

    const value = { pokemon, trainer, pokemonHistory, load, getAllInfo, loadHistory };

    return <PokemonOneContext.Provider value={value}>{children}</PokemonOneContext.Provider>;
};

export const usePokemonOne = () => useContext(PokemonOneContext);