import React from 'react';

import './PaymentHeader.css';
import base_ball_tinkoff_icon from "../../../assets/icons/base_ball_tinkoff_icon.svg";

const PaymentHeader = ({text=true}) => {
    return (
        <header className="payment_header">
            <div className="payment_header_content">
                <img src={base_ball_tinkoff_icon} alt="logo пикачунькофф" className="payment_header_content_logo" />
                <div className="payment_header_content_title">
                    <h2 className="payment_header_content_title_h2 style_1_caption_12_700 style_1_text_uppercase">Пикачунькофф</h2>
                    {text ? <p className="payment_header_content_title_text style_1_caption_8_700 style_1_text_uppercase">касса</p> : ''}
                </div>
            </div>
        </header>
    );
};

export default PaymentHeader;