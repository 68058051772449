import React, {useState} from 'react';

import "./PokemonExport.css";
import Cookies from 'js-cookie';

import base_export_icon from "../../../../assets/icons/base_export_icon.svg";
import {usePokemonExport} from "./PokemonExportContext";
import axios from "axios";
import {useNavigate} from "react-router-dom";

const PokemonExport = ({trainer_id, battles}) => {
    const navigate = useNavigate();
    const [arrayStatusExportRender, setarrayStatusExportRender] = useState(false);

    const { arrayStatusExport, statusExport, sendExport, clearExport } = usePokemonExport();

    const [exportText, setExportText] = useState('Заказать');
    const [exportDesc, setExportDesc] = useState('Файл придёт на почту тренера');
    const [exportType, setExportType] = useState(0);

    const handleClick = async (e) => {
        if (exportType === 2) {
            navigate('/profile')
        } else if (exportType === 1) {
            var data = await sendExport({trainer_id})

            if (data.status === 201){
                setExportType(0);
                setExportDesc('Идет подготовка файла...');
                setExportText('Заказать');
            }
        }
    };

    if (arrayStatusExport && !arrayStatusExportRender) {
        if (!Cookies.get('login') || Cookies.get('login') === '' || Cookies.get('email_confirm') === 'false') {
            setExportText('Привязать');
            setExportDesc('Сначала необходимо<br>привязать почту');
            setExportType(2);
        } else {
            if (battles.length === 0) {
                setExportType(0);
                setExportDesc('История битв пуста');
                setExportText('Заказать');

                console.log('test')
            } else {
                var data_BHRs = arrayStatusExport.data;
                var block_export = false;
                console.log(data_BHRs);

                var count_BHR_in_hour = 0;
                for (var i = 0; i < data_BHRs.length; i++) {
                    var date_BHR = new Date(data_BHRs[i].date_requested).getTime();
                    var date = new Date().getTime() - 3600000;

                    if (date_BHR > date) {
                        count_BHR_in_hour++;
                    }

                    if (data_BHRs[i].status === 'waiting' || data_BHRs[i].status === 'in_progress') {
                        setExportType(0);
                        setExportDesc('Идет подготовка файла...');
                        setExportText('Заказать');

                        block_export = true;
                    }
                }

                if (count_BHR_in_hour >= 20) {
                    setExportType(0);
                    setExportDesc('Можно заказывать<br class="k_one"> не больше<br class="k_two"> 20 раз в час');
                    setExportText('Заказать');

                    block_export = true;
                }

                if (block_export === false) {
                    setExportType(1);
                    setExportDesc('Файл придёт на почту тренера');
                    setExportText('Заказать');
                }
            }
        }

        setarrayStatusExportRender(true);
        clearExport();
    }

    React.useEffect(() => {
        if (trainer_id && !arrayStatusExport && !arrayStatusExportRender && !isNaN(trainer_id)){
            statusExport({trainer_id})
        }
    }, []);

    return (
        <div className={"k_cart_trainer_export" + (exportType === 0 ? ' feature-empty' : '')}>
            <img src={base_export_icon} className="k_cart_trainer_export_icon" />
            <div className="k_cart_trainer_export_box">
                <div className="k_cart_trainer_export_box_title style_1_caption_16_400 style_1_text_left">Экспорт истории битв</div>
                <div className="k_cart_trainer_export_box_desc style_1_caption_12_400 style_1_text_left" dangerouslySetInnerHTML={{__html: exportDesc}}></div>
            </div>
            <button className={"k_cart_trainer_export_button style_1_caption_16_500" + (exportType === 0 ? ' disable' : '')} onClick={handleClick}>
                <span className="k_cart_trainer_export_button_border"></span>
                <span>{exportText}</span>
            </button>
        </div>
    );
};

export default PokemonExport;