import React, {useState} from 'react';

import "./PokemonPremium.css";
import $ from "jquery";
import Cookies from 'js-cookie';

import base_premium_icon from "../../../assets/icons/base_premium_icon.png";
import {useNavigate} from "react-router-dom";
import base_arrow_left from "../../../assets/icons/base_arrow_left.svg";
import base_close_icon from "../../../assets/icons/base_close_icon.svg";

const PokemonPremium = ({user}) => {
    const navigate = useNavigate();

    const handleLinkPremium = (e) => {
        navigate('/premium', {replace: false});
    };

    return (
        <div className="k_trainer_in_button k_cart_trainer_premium" onClick={handleLinkPremium}>
            <img src={base_premium_icon} className="k_trainer_in_button_icon" />
            <div className="k_trainer_in_button_wrapper">
                <div className="k_trainer_in_button_title_no_desc k_trainer_in_button_title style_1_caption_16_400 style_1_text_left">Pokemon Premium
                {user.premium_duration ?
                <div className="history-info-duration">
                    <div className="history-info-duration-num">{user.premium_duration}</div>
                    <div className="history-info-duration-day">дней</div>
                </div> : ''}</div>
            </div>
            <button id="dropdown" className="pokemon_one_body_content_inner_hystory_battle_link_button">
                <img src={base_arrow_left} width="26" height="26" alt="история битв" />
            </button>
        </div>
    );
};

export default PokemonPremium;