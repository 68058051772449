import React, { useState, useEffect } from 'react';
import base_search_vector_icon from "../../../assets/icons/base_search_vector_icon.svg"
import base_close_vector_icon from "../../../assets/icons/base_close_vector_icon.svg"
import $ from "jquery";
import Cookies from 'js-cookie';

import "./../FilterTypeSearch.css";
import {useTrainerList} from "../../../pages/TrainerList/TrainerListContext";
var search_int = false;
Cookies.set('search_pokemons', '', { expires: 365 });

const FilterTypeSearch = ({type}) => {
    const { sort_new } = useTrainerList();
    const [ShowCloseBtn, setShowCloseBtn] = React.useState(false);
    const [SearchTrainersValue, setSearchTrainersValue] = React.useState('');

    const [ShowMobileSearch, setShowMobileSearch] = React.useState(false);

    const handleInputSearch = async (e) => {
        setSearchTrainersValue(e.target.value);

        if($('#filter_type_search_1').val().length >= 1) {
            setShowCloseBtn(true);
        } else if($('#filter_type_search_1').val().length === 0) {
            setShowCloseBtn(false);
        }

        clearTimeout(search_int)
        search_int = setTimeout(async function(){
            console.log('start_search')
            Cookies.set('search_trainers', $('#filter_type_search_1').val(), { expires: 365 });

            await sort_new('');
            clearTimeout(search_int);
        }, 1200)
    };

    const handleClickClear = async (e) => {
        setSearchTrainersValue('');
        setShowCloseBtn(false);

        Cookies.set('search_trainers', '', { expires: 365 });

        await sort_new('');
    }

    const handleClickShowMobileSearch = async (e) => {
        setShowMobileSearch(!ShowMobileSearch);

        if (!ShowMobileSearch){
            $('.filter_type_sort').addClass('close');
            $('.right_block').addClass('mobile_open');
            if($('#filter_type_search_1').val().length >= 1) {
                setShowCloseBtn(true);
            }
        }else{
            $('.filter_type_sort').removeClass('close');
            $('.right_block').removeClass('mobile_open');

            setShowCloseBtn(false);
        }

    }

    return (
        <>
            <div className={"filter_type_search" + (ShowMobileSearch ? ' mobile_open' : '')}>
                <div className="filter_type_search_icon" onClick={handleClickShowMobileSearch}>
                    <img src={base_search_vector_icon}/>
                </div>
                <input id="filter_type_search_1" onChange={handleInputSearch} value={SearchTrainersValue} className="filter_type_search_field style_1_interactive_button_link" placeholder="Поиск" />
                <div className={"filter_type_search_icon_clear" + (ShowCloseBtn ? ' show' : '')} onClick={handleClickClear}>
                    <img src={base_close_vector_icon}/>
                </div>
            </div>
            <div className={"filter_type_search_icon_close" + (ShowMobileSearch ? ' mobile_open' : '')} onClick={handleClickShowMobileSearch}>
                <img src={base_close_vector_icon}/>
            </div>
        </>
    );
};

export default FilterTypeSearch;