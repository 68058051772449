import React from 'react';
import PropTypes from 'prop-types';
import {Link, useNavigate} from 'react-router-dom';

import './BackButton.css';
import base_arrow_left from "../../../assets/icons/base_arrow_left.svg";
import $ from "jquery";

const BackButton = ({link = false}) => {
    const navigate = useNavigate();

    const handleLinkBack = (e) => {
        if (!link){
            navigate(-1)
        }else{
            navigate(link, {replace: false})
        }
    };

    return (
        <Link to={link} className="top_back_button" onClick={handleLinkBack}>
            <img className="top_back_button_icon" src={base_arrow_left} alt="вернуться назад" />
            <span className="top_back_button_text style_1_caption_16_400">Назад</span>
        </Link>
    );
};

BackButton.propTypes = {
    link: PropTypes.string
};

export default BackButton;