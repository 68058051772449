import React from 'react';

import "./PokemonHistoryList.css";
import {FormatDate2, generatePokemonImageUrl, trainer_or_pokemons_open_lists} from "../../../helpers/helpers";
import $ from "jquery";

import base_clock_icon from "../../../assets/icons/base_clock_icon.svg"
import base_arrow_left from "../../../assets/icons/base_arrow_left.svg";
import base_close_icon from "../../../assets/icons/base_close_icon.svg";
import base_skull_white_icon from "../../../assets/icons/base_skull_white_icon.svg"
import LevelOrAttack from "../../Interactive/LevelOrAttack/LevelOrAttack";
import {useNavigate, useParams} from "react-router-dom";


const PokemonHistoryList = ({pokemon = false, history_list = false, history_list_uncorrect = false, is_allow_render = true}) => {
    const [PokemonListOpen, SetPokemonListOpen] = React.useState(false);
    const [PokemonListHeight, SetPokemonListHeight] = React.useState('400px');
    const [PokemonListActive, SetPokemonListActive] = React.useState(false);
    const params = useParams();

    if (history_list[0]) {
        if (+history_list[0]['my']['id'] !== +params.pokemonId) {
            is_allow_render = false;
        }
    }

    if (!is_allow_render){
        history_list = [];
        history_list_uncorrect = [];
    }

    React.useEffect(() => {
        if (PokemonListOpen) {
            change_open();
        }
    },[params.pokemonId]);

    const navigate = useNavigate();

    function change_open(){
        SetPokemonListActive(true);
        SetPokemonListOpen(!PokemonListOpen);

        if (PokemonListOpen) {
            $('.trainer-item .content__inner').css('gap', '');

            $('section.trainer-item.single-trainer').css('margin-bottom', '80px')
            trainer_or_pokemons_open_lists(0, 2)

        } else {
            if(window.innerWidth > 768) {
                var content_h = trainer_or_pokemons_open_lists(0, 1);

                SetPokemonListHeight(content_h);
            }

            return false
        }
    }

    const handleClick = () => {
        change_open()
    };

    if (history_list_uncorrect){
        history_list = [];
        var my = [];
        var enemy = [];

        for (var i=0; i < history_list_uncorrect.length; i++){
            if (history_list_uncorrect[i].my_id === history_list_uncorrect[i].attack_pokemon.id){
                my = history_list_uncorrect[i].attack_pokemon;
                enemy = history_list_uncorrect[i].defend_pokemon;
            }else{
                my = history_list_uncorrect[i].defend_pokemon;
                enemy = history_list_uncorrect[i].attack_pokemon;
            }

            my.add = my;
            enemy.add = enemy;

            pokemon = my;

            history_list.push({
                'add': {
                    'date': history_list_uncorrect[i].date,
                    'winner': history_list_uncorrect[i].winner_id,
                    'id': history_list_uncorrect[i].id
                },
                'my': my,
                'enemy': enemy
            })
        }
    }

    const handleLinkPokemon = (e) => {
        var el = $(e.target).closest('.history_battle_content_link');

        navigate('/pokemon/'+el.attr('data-id')+'/');
    };

    return (
        <div className={"pokemon_one_body_content_inner_hystory_battle " + (history_list && history_list.length > 0 ? '' : "feature-empty") + (!PokemonListOpen ? "" : " down")}>
            <a className="pokemon_one_body_content_inner_hystory_battle_link style_1_caption_16_400 pokemon_one_body_content_inner_box" onClick={handleClick}>
                <img src={base_clock_icon} width="24" height="24" alt="история битв" />
                <div className="history-info">
                    История битв <span className="total-count history-info_count">{history_list && history_list.length > 0 ? history_list.length : 0}</span>
                </div>
                <button className={"pokemon_one_body_content_inner_hystory_battle_link_button" + (!PokemonListOpen ? "" : " down")}>
                    <img src={!PokemonListOpen ? base_arrow_left: base_close_icon} width="26" height="26" alt="история битв" />
                </button>
            </a>
            <div className="pokemon_one_body_content_inner_info_mobile">
                <button className="pokemon_one_body_content_inner_info_mobile_button" onClick={handleClick}>
                    <img alt="Стрелка влево" src={base_arrow_left}/>
                </button>
                <div className="pokemon_one_body_content_inner_info_mobile_title style_1_caption_16_500">История битв</div>
            </div>
            <div className={"history_battles" + (PokemonListOpen ? ` down` : ``)} style={{'height': PokemonListHeight+'px'}}>
                {history_list ? history_list.map((battle, index) => (
                    <div className="history_battles_one" style={{'order': "-"+battle.add.id}}>
                        <time className="date" dangerouslySetInnerHTML={{__html: FormatDate2(battle.add.date)}}></time>
                        <div className="history_battle_content">
                            <div className={"history_battle_content_cart_my" + (battle.add.winner === battle.my.id ? ' win' : ' defeat')}>
                                <div className="history_battle_content_mobile">
                                    <div className="history_battle_content_link" data-id={battle.my.id} onClick={handleLinkPokemon}>
                                        <img className="history_battle_content_link_image" src={generatePokemonImageUrl((battle.my.add?.photo_id !== undefined ? battle.my.add.photo_id : pokemon.photo_id))} alt="" />
                                        <div className="history_battle_content_link_id style_1_caption_12_400">{battle.my.id}</div>
                                    </div>
                                    <div className="history_battle_content_info">
                                        <div className="history_battle_content_info_title style_1_caption_16_400">{battle.my.add ? battle.my.add.name : pokemon.name}</div>
                                        <LevelOrAttack value={battle.my.attack} />
                                        <div className="history_battle_content_link_id history_battle_content_link_id_mobile style_1_caption_12_400">{battle.my.id}</div>
                                    </div>
                                </div>
                                <div className="history_battle_content_icon_batlle"></div>
                            </div>
                            <div className={"history_battle_content_cart_other" + (battle.add.winner !== battle.my.id ? ' win' : ' defeat')}>
                                <div className="history_battle_content_icon_batlle"></div>
                                <div className="history_battle_content_mobile">
                                    <div className="history_battle_content_link" data-id={battle.enemy.add.id} onClick={handleLinkPokemon}>
                                        <img className="history_battle_content_link_image" src={generatePokemonImageUrl(battle.enemy.add.photo_id)} alt="" />
                                        <div className="history_battle_content_link_id style_1_caption_12_400">{battle.enemy.add.id}</div>
                                    </div>
                                    <div className="history_battle_content_info">
                                        <div className="history_battle_content_info_title style_1_caption_16_400">{battle.enemy.add.name}</div>
                                        <LevelOrAttack value={battle.enemy.attack} />
                                        <div className="history_battle_content_link_id history_battle_content_link_id_mobile style_1_caption_12_400">{battle.enemy.add.id}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"history_battle_content_status " + (battle.add.winner === battle.my.id ? 'win' : 'defeat')}>
                            <img src={battle.add.winner === battle.my.id ? '' : base_skull_white_icon} width="16" height="18" />
                            <span>{(battle.add.winner === battle.my.id ? 'Победа' : 'Поражение')} {pokemon.name}!</span>
                        </div>
                    </div>

                    )) : ''}
            </div>
        </div>
    );
};

export default PokemonHistoryList;