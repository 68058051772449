import React, { createContext, useContext, useState } from 'react';
import axios from "axios";
import Cookies from 'js-cookie';
import $ from "jquery";

const PremiumContext = createContext(undefined);

export const PremiumProvider = ({ children }) => {
    const [rating, setRating] = useState([]);
    const [lastUpdate, setLastUpdate] = useState([]);

    const load = async () => {
        const asyncFn = async () => {
            await axios.get(process.env.REACT_APP_RATING_API + '/rating', {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'trainer_token': Cookies.get('session_id')
                }
            }).then(function (response) {
                if (response.status === 200) {
                    setRating(response.data.table);
                    setLastUpdate(response.data.last_update)
                }
            })
        };

        asyncFn();
    };

    const value = { rating, lastUpdate, load };

    return <PremiumContext.Provider value={value}>{children}</PremiumContext.Provider>;
};

export const usePremium = () => useContext(PremiumContext);