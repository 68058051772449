import React from 'react';
import {
    Box,
    FormHelperText,
} from '@mui/material';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import './ChangePassword.css';

import { useChangePassword } from "./ChangePasswordContext";
import ClassicTitle from "../../../components/PopupsWhite/ClassicTitle/ClassicTitle";
import SubTitleAndSubText from "../../../components/PopupsWhite/SubTitleAndSubText/SubTitleAndSubText";
import SendButtonStandart from "../../../components/PopupsWhite/SendButtonStandart/SendButtonStandart";
import LinkStandart from "../../../components/PopupsWhite/LinkStandart/LinkStandart";
import InputStandart from "../../../components/PopupsWhite/InputStandart/InputStandart";
import Gradient from "../../../components/Layout/Gradient/Gradient";

const ChangePasswordPage = () => {
    const { change_password_email } = useChangePassword();

    return (
        <>
            <Gradient type="gradient_bottom" />
            <section className="style_1_popup_white_out style_1_popup_all_page">
                <div className="style_1_popup_white_in">
                    <ClassicTitle title="Битва покемонов" />
                    <SubTitleAndSubText link="/login" subtitle="Восстановление пароля" subtext="Введите почту, которую указывали при регистрации" />

                    <Formik
                        initialValues={{
                            email: ''
                        }}
                        validationSchema={Yup.object().shape({
                            email: Yup.string().max(255).required('Введите почту')
                        })}
                        onSubmit={async (values, {setErrors}) => {
                            await change_password_email(values, {setErrors});
                        }}
                    >
                        {({ errors, handleBlur, handleChange, isSubmitting, touched, values }) => (
                            <Form>
                                <div className="style_1_popup_white_form_wrapper_fields">
                                    <InputStandart label="Почта" name="email" onBlur={handleBlur} onChange={handleChange} touched={touched.email} error={errors.email} value={values.email} />
                                </div>

                                {errors.submit && (
                                    <Box mt={3} className='k_error_submit'>
                                        <FormHelperText error>{errors.submit}</FormHelperText>
                                    </Box>
                                )}

                                <SendButtonStandart title="Восстановить" is_disabled={isSubmitting} />
                                <LinkStandart link="/login" title="Войти" />
                            </Form>
                        )}
                    </Formik>
                </div>
            </section>
        </>
    );
};

export default ChangePasswordPage;