import React from 'react';
import "./Rating.css";

import icon_align_end_horizontal from "../../assets/icons/rating/icon_align_end_horizontal.svg";
import icon_download from "../../assets/icons/rating/icon_download.svg";
import icon_percent from "../../assets/icons/rating/icon_percent.svg";
import icon_russian_ruble from "../../assets/icons/rating/icon_russian_ruble.svg";
import icon_star from "../../assets/icons/rating/icon_star.svg";
import icon_swords from "../../assets/icons/rating/icon_swords.svg";
import icon_user from "../../assets/icons/rating/icon_user.svg";

import {useRating} from "./RatingContext";
import {FormatDate1} from "../../helpers/helpers";
import $ from "jquery";

import {useNavigate} from "react-router-dom";

const RatingPage = () => {
    const { rating, lastUpdate, load } = useRating();
    const navigate = useNavigate();

    $(document).scroll(function() {
        if ($('.rating_page_table_content')[0]) {
            if ($('.rating_page_table_content')[0].offsetTop < ($(document).scrollTop())) {
                $('.rating_page_table_content').addClass('sticky');
            } else {
                $('.rating_page_table_content').removeClass('sticky');
            }
        }
    })

    const handleLinkRatingTrainer = (e) => {
        var el = $(e.target).closest('.trainers__link');

        navigate('/trainer/'+el.attr('data-id')+'/', {replace: false});
    };

    React.useEffect(() => {
        load()
    }, []);

    return (
        <div className="rating_page">
            <div className="gradient"></div>
            <div className="rating_page_top">
                <div>
                    <h1 className="rating_page_top_title style_1_heading_38_400_pokemon_classic style_1_text_left">Рейтинг</h1>
                    <span className="rating_page_top_subtitle style_1_caption_16_500">100 лучших тренеров «Битвы Покемонов»</span>
                </div>
                <font className="rating_page_top_date style_1_caption_16_500">Сезон 2025</font>
            </div>
            <section className="rating_page_table">
                <div className="rating_page_table_body">
                    <table className="rating_page_table_content">
                        <thead>
                            <tr>
                                <th scope="col">№</th>
                                <th scope="col"><img src={icon_user} alt="icon"/> <font>Тренер</font></th>
                                <th scope="col"><img src={icon_star} alt="icon"/> <font>Рейтинг</font></th>
                                <th className="hidden_mobile" scope="col"><img src={icon_swords} alt="icon"/> <font>Всего битв</font></th>
                                <th className="hidden_mobile" scope="col"><img src={icon_percent} alt="icon"/> <font>Победы</font></th>
                                <th className="hidden_mobile" scope="col"><img src={icon_align_end_horizontal} alt="icon"/> <font>В/П</font></th>
                                <th className="hidden_mobile" scope="col"><img src={icon_russian_ruble} alt="icon"/> <font>Потрачено</font></th>
                                <th className="hidden_mobile" scope="col"><img src={icon_download} alt="icon"/> <font>Загрузок</font></th>
                            </tr>
                        </thead>
                        <tbody>
                            {rating ? rating.map((rating) => (
                                <tr className="k_rating_table_r trainers__link" data-id={rating.trainer_id} onClick={handleLinkRatingTrainer}>
                                    <td><div className={"k_cart_trainer_rate_img" + ((+rating.rank < 4) ? " k_cart_trainer_rate_img_"+rating.rank : '')}>{rating.rank}</div></td>
                                    <td className="rating_table_r_trainer_name trainers__title-text">{rating.name}</td>
                                    <td className="rating_table_r_rate">{rating.rating}</td>
                                    <td className="rating_table_r_battles hidden_mobile">{rating.battles_num}</td>
                                    <td className="rating_table_r_wins hidden_mobile">{Number(((rating.battles_wins / rating.battles_num) * 100).toFixed(1))}</td>
                                    <td className="rating_table_r_v_p hidden_mobile">{rating.battles_wins + "/" + rating.battles_defeat}</td>
                                    <td className="rating_table_r_sells hidden_mobile">{rating.total_amount}</td>
                                    <td className="rating_table_r_downloads hidden_mobile">{rating.downloads_num}</td>
                                </tr>
                                )) : ''
                            }
                        </tbody>
                    </table>
                </div>
            </section>
            <div className="rating_page_bottom_date">
                <font className="style_1_caption_16_400">Данные на <font className="k_date">{FormatDate1(lastUpdate)}</font></font>
            </div>
        </div>
    );
};

export default RatingPage;