import React from 'react';
import PropTypes from 'prop-types';

import './ClassicTitle.css';

const ClassicTitle = ({title}) => {
    return (
        <h1 className="style_1_popup_white_title style_1_classic_26_400 style_1_line_height_42px">{title}</h1>
    );
};

ClassicTitle.propTypes = {
    title: PropTypes.string
};

export default ClassicTitle;