import React from 'react';

import './PaymentReceipt.css';
import $ from "jquery";

import Cookies from 'js-cookie';
import {KNumberPriceFormat} from "../../../helpers/helpers";

const PaymentReceipt = ({price = Cookies.get('user_buy_price'), product_title, type='product'}) => {
    const [receiptOpen, SetReceiptOpen] = React.useState(false);

    const handleReceiptOpen = () => {
        SetReceiptOpen(!receiptOpen);

        if (!receiptOpen){
            $('.payment_receipt_open_block').slideDown();
        }else{
            $('.payment_receipt_open_block').slideUp();
        }

    };

    return (
        <div className="payment_receipt">
            <div className="payment_receipt_open_button">
                <p className="payment_receipt_open_button_title style_1_caption_24_500">{KNumberPriceFormat(price)} ₽</p>
                <button className={"payment_receipt_open_button_icon" + (receiptOpen ? ' open' : '')} onClick={handleReceiptOpen} />
            </div>
            <div className={"payment_receipt_open_block" + (receiptOpen ? ' open' : '')} style={{'display': 'none'}}>
                <div className="payment_receipt_open_block_title">
                    <p className="payment_receipt_open_button_title style_1_caption_24_500">{KNumberPriceFormat(price)} ₽</p>
                    <button className="payment_receipt_open_button_icon_close" onClick={handleReceiptOpen} />
                </div>

                <div className="payment_receipt_open_block_table">
                    <div className="payment_receipt_open_block_flex_left style_1_caption_14_400">Магазин</div>
                    <div className="payment_receipt_open_block_flex_right style_1_caption_14_400">
                        <a href={"https://"+process.env.REACT_APP_MAIN_URL_REACT}>{process.env.REACT_APP_MAIN_URL_REACT}</a>
                    </div>

                    {type === 'product' ?
                        <>
                            <div className="payment_receipt_open_block_flex_left style_1_caption_14_400">Номер заказа</div>
                            <div className="payment_receipt_open_block_flex_right style_1_caption_14_400">
                                pb:3112220001
                            </div>

                            <div className="payment_receipt_open_block_flex_left style_1_caption_14_400">Описание</div>
                            <div className="payment_receipt_open_block_flex_right style_1_caption_14_400">
                                {product_title}
                            </div>
                        </>
                        :
                        <>
                            <div className="payment_receipt_open_block_flex_left style_1_caption_14_400">Номер карты</div>
                            <div className="payment_receipt_open_block_flex_right style_1_caption_14_400">
                                {Cookies.get('user_buy_card')}
                            </div>

                            <div className="payment_receipt_open_block_flex_left style_1_caption_14_400">Код отправлен на номер</div>
                            <div className="payment_receipt_open_block_flex_right style_1_caption_14_400">
                                *01-99
                            </div>
                        </>
                    }
                </div>

                <button type='submit' className='style_1_base_button_payment' onClick={handleReceiptOpen}>ОК</button>
            </div>
        </div>
    );
};

export default PaymentReceipt;