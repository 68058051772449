import React, { createContext, useContext, useState } from 'react';
import axios from "axios";

const ChangePasswordContext = createContext(undefined);

export const ChangePasswordProvider = ({ children }) => {
    const change_password_email = async (values, {setErrors}) => {
        var formData = new FormData();
        formData.append('email', values['email']);
        formData.append('action', 're_send');

        await axios.post(`${process.env.REACT_APP_NEW_API}/trainers/re`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(function (response) {
            if (response.status === 200) {
                window.location.href = '/confirmation_new_password_email';
            }
        }).catch(function (response) {
            if (response.status !== 200) {
                var errors = [];

                errors['email'] = 'Аккаунт с такой почтой не найден';

                setErrors(errors);
            }
        });
    };

    const value = { change_password_email };

    return <ChangePasswordContext.Provider value={value}>{children}</ChangePasswordContext.Provider>;
};

export const useChangePassword = () => useContext(ChangePasswordContext);