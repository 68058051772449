import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';

import "./CopyNumber.css";
//import './Button.css';
import $ from "jquery";

const CopyNumber = ({id}) => {
    function copy_number_f(event){
        var el = $(event.target).closest('.copy_number');
        var el_id = $(el).find('.copy_number_id');
        var el_button = $(el).find('.copy_number_button');

        event.stopPropagation();
        if (el.attr('data-block') !== 'true') {
            navigator.clipboard.writeText(el_id.text()).then(function() {
                console.log('Async: Copying to clipboard was successful!');
            }, function(err) {
                console.error('Async: Could not copy text: ', err);
            });

            setTimeout(function ( el_button, el ) {
                el_button.css('opacity', '0.3');
                el.attr('data-block', 'true');
            }, 50, el_button, el);

            setTimeout(function ( el_button ) {
                el_button.css({'opacity': '0.5', 'scale': '0.5'});
            }, 100, el_button);

            setTimeout(function ( el_button ) {
                el_button.css({'opacity': '1', 'scale': '1'});
            }, 200, el_button);

            setTimeout(function ( el_id ) {
                el_id.fadeOut(100);
            }, 300, el_id);

            setTimeout(function ( el ) {
                if (el.attr('data-last') === '' || !el.attr('data-last')) {
                    el_id.attr('data-last', el_id.text());
                    el_id.text('Скопировано');
                }

                el_id.fadeIn(300);
            }, 400, el, el_id);

            setTimeout(function ( el_id ) {
                el_id.fadeOut(100);
            }, 1000, el_id);

            setTimeout(function ( el_id, el ) {
                el_id.fadeIn(300);
                el_id.text(el_id.attr('data-last'));
                el_id.attr('data-last', '');
                el.attr('data-block', 'false');
            }, 1100, el_id, el);
        }
    }

    return (
        <div className="copy_number" onClick={copy_number_f}>
            <div className="copy_number_id">{id}</div>
            <button className="copy_number_button"></button>
        </div>
    );
};

CopyNumber.propTypes = {
    id: PropTypes.string,
};

export default CopyNumber;