import React from 'react';

import './SocialAuth.css';
import {Link} from "react-router-dom";
import VK from "../../../assets/icons/VK_Logo.svg";

const SocialAuth = ({title}) => {
    const url_to_google = 'https://id.vk.com/auth?uuid='+process.env.REACT_APP_TECH_API_VK_SECRET_PUBLIC_HASH+'&app_id='+process.env.REACT_APP_TECH_API_VK_APP_ID+'&response_type=silent_token&redirect_uri='+process.env.REACT_APP_NEW_API+'/technical_routes/callback_vk';

    return (
        <>
            <p className="auth_content_text style_1_caption_14_500">{title}</p>
            <div className="auth_content_socials">
                <Link to={url_to_google} className="auth_content_social">
                    <img
                        src={VK}
                        className="auth_content_social_icon"
                        alt="Иконка социальной сети"
                    />
                </Link>
            </div>
        </>
    );
};

export default SocialAuth;