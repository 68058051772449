import React from 'react';
import PropTypes from 'prop-types';

import "./LevelOrAttack.css";

const LevelOrAttack = ({value, type="attack"}) => {
    return (
        <div className={"style_1_caption_14_500 level_or_attack level_or_attack_type_"+type+"_"+value}>{type === 'attack' ? 'Атака ' : 'Уровень '}{value}</div>
    );
};

LevelOrAttack.propTypes = {
    value: PropTypes.number,
    type: PropTypes.string,
};

export default LevelOrAttack;