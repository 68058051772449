import React, { useState } from 'react';

import Achievement1 from "../../../../assets/icons/achievements/Achievement1.png"
import Achievement2 from "../../../../assets/icons/achievements/Achievement2.png"
import Achievement3 from "../../../../assets/icons/achievements/Achievement3.png"
import Achievement4 from "../../../../assets/icons/achievements/Achievement4.png"
import Achievement5 from "../../../../assets/icons/achievements/Achievement5.png"
import Achievement6 from "../../../../assets/icons/achievements/Achievement6.png"
import Achievement7 from "../../../../assets/icons/achievements/Achievement7.png"
import Achievement_top_popup_icon from "../../../../assets/icons/achievements/Achievement_top_popup_icon.png"

import "./TrainerAchievements.css";
import $ from "jquery";

const TrainerAchievements = ({achievements}) => {
    const [achievementsTitle, setAchievementsTitle] = useState('По одному за раз');
    const [achievementsDesc, setAchievementsDesc] = useState('5 побед подряд одним покемоном');
    const [achievementsPopupOpen, setAchievementsPopupOpen] = useState(false);

    var achievements_animate_prev = false;
    var timer_achievements = false;

    const handleMouseLeaveAchievement = (e) => {
        if ($('.achievements_popup:hover')[0]) {
            return false;
        }

        $('.achievements_icon:hover').each(function (){
            return false;
        })

        achievements_animate_prev = false;

        timer_achievements = setTimeout(function (){
            setAchievementsPopupOpen(false);
        }, 200)
    }

    const handleMouseEnterAchievementsPopup = (e) => {
        clearTimeout(timer_achievements);
    }

    const handleMouseEnterAchievements = (e) => {
        var el = $(e.target).closest('.achievements_icon');

        if ($(el).attr('data-slug') === 'beginning'){
            setAchievementsTitle('Начало большого пути');
            setAchievementsDesc('Первая победа');
        }
        if ($(el).attr('data-slug') === 'out_of_battles'){
            setAchievementsTitle('Упёртый как бык');
            setAchievementsDesc('Достиг дневного лимита битв');
        }
        if ($(el).attr('data-slug') === 'self_knockout'){
            setAchievementsTitle('Будет<br>что вспомнить');
            setAchievementsDesc('Отправил своего покемона в нокаут');
        }
        if ($(el).attr('data-slug') === 'max_level'){
            setAchievementsTitle('Чемпион');
            setAchievementsDesc('Достиг 5 уровня');
        }
        if ($(el).attr('data-slug') === 'one_vs_seven'){
            setAchievementsTitle('Один на миллион');
            setAchievementsDesc('Атака 1 победила атаку 7');
        }
        if ($(el).attr('data-slug') === 'five_battles'){
            setAchievementsTitle('По одному за раз');
            setAchievementsDesc('5 побед подряд одним покемоном');
        }
        if ($(el).attr('data-slug') === 'five_defends'){
            setAchievementsTitle('Крепкий орешек');
            setAchievementsDesc('Покемон выдержал 3 нападения подряд');
        }

        var i_icon = 0;
        var this_icon = el[0];
        var i_conf_find = 1;
        $('.achievements_icon').each(function (){
            if (i_conf_find === 1) {
                if (this === this_icon) {
                    i_conf_find = 0;
                } else {
                    i_icon += 1;
                }
            }
        })

        var add_pixels = 40;
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent) || window.innerWidth < 450) {
            add_pixels = 32;
        }

        if (i_icon !== 0){
            add_pixels += 4
        }

        achievements_check_offset(add_pixels, i_icon);

        clearTimeout(timer_achievements);
        if (achievements_animate_prev === false){
            setTimeout(function (){
                setAchievementsPopupOpen(true);
            }, 200)
            achievements_animate_prev = true;
        }
    };

    var add_pixels_top = 0;
    function achievements_check_offset(add_pixels, i_icon){
        var left_spaccing = ((add_pixels * i_icon) - ($('.achievements_popup').width()/2-(add_pixels-4)/2));

        $('.achievements_popup_top').css('left', ($('.achievements_popup').width()/2-26) + 'px');

        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
            if (left_spaccing < -10) {
                left_spaccing = -10;

                if (i_icon !== 0) {
                    add_pixels_top = 4
                }

                $('.achievements_popup_top').css('left', ((((32 + add_pixels_top) * (i_icon + 1)) - 26) - 10) + 'px');
            }
        }

        $('.achievements_popup').css('left', left_spaccing + 'px');
    }

    var has_achievements = [];
    for (var i = 0; i < achievements.length; i++){
        has_achievements[achievements[i].slug] = 1;
    }

    return (
        <div className="achievements">
            <div className={"achievements_icon beginning-icon" + (has_achievements['beginning'] ? ' active' : '')} data-slug="beginning" onMouseEnter={handleMouseEnterAchievements} onMouseLeave={handleMouseLeaveAchievement}>
                <img src={Achievement1} width="30px" height="30px" />
            </div>
            <div className={"achievements_icon out_of_battles-icon" + (has_achievements['out_of_battles'] ? ' active' : '')} data-slug="out_of_battles" onMouseEnter={handleMouseEnterAchievements} onMouseLeave={handleMouseLeaveAchievement}>
                <img src={Achievement2} width="32px" height="32px" />
            </div>
            <div className={"achievements_icon self_knockout-icon" + (has_achievements['self_knockout'] ? ' active' : '')} data-slug="self_knockout" onMouseEnter={handleMouseEnterAchievements} onMouseLeave={handleMouseLeaveAchievement}>
                <img src={Achievement3} width="28px" height="28px" />
            </div>
            <div className={"achievements_icon max_level-icon" + (has_achievements['max_level'] ? ' active' : '')} data-slug="max_level" onMouseEnter={handleMouseEnterAchievements} onMouseLeave={handleMouseLeaveAchievement}>
                <img src={Achievement4} width="30px" height="30px" />
            </div>
            <div className={"achievements_icon one_vs_seven-icon" + (has_achievements['one_vs_seven'] ? ' active' : '')} data-slug="one_vs_seven" onMouseEnter={handleMouseEnterAchievements} onMouseLeave={handleMouseLeaveAchievement}>
                <img src={Achievement5} width="30px" height="30px" />
            </div>
            <div className={"achievements_icon five_battles-icon" + (has_achievements['five_battles'] ? ' active' : '')} data-slug="five_battles" onMouseEnter={handleMouseEnterAchievements} onMouseLeave={handleMouseLeaveAchievement}>
                <img src={Achievement6} width="28px" height="28px" style={{'width': '28px', 'height': '28px'}} />
            </div>
            <div className={"achievements_icon five_defends-icon" + (has_achievements['five_defends'] ? ' active' : '')} data-slug="five_defends" onMouseEnter={handleMouseEnterAchievements} onMouseLeave={handleMouseLeaveAchievement}>
                <img src={Achievement7} width="28px" height="28px" style={{'width': '28px', 'height': '28px'}} />
            </div>
            <div className={"achievements_popup" + (achievementsPopupOpen ? ' open' : ' close')} onMouseEnter={handleMouseEnterAchievementsPopup} onMouseLeave={handleMouseLeaveAchievement}>
                <div className="achievements_popup_top">
                    <img src={Achievement_top_popup_icon} />
                </div>
                <div className="achievements_popup_content">
                    <div className="achievements_popup_content_title" dangerouslySetInnerHTML={{__html: achievementsTitle}}></div>
                    <div className="achievements_popup_content_txt" dangerouslySetInnerHTML={{__html: achievementsDesc}}></div>
                </div>
            </div>
        </div>
    );
};

export default TrainerAchievements;