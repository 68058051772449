import React from 'react';
import './Header.css';

import HeaderNav from "./Nav";
import HeaderMobile from "./HeaderMobile/HeaderMobile";

const Header = () => {
    return (
        <header className="header">
            <HeaderNav />
            <HeaderMobile />
        </header>
    );
};

export default Header;