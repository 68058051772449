import React from 'react';

import InteractiveButton from "../../Interactive/Button/Button";
import header_pokemons_icon from "../../../assets/icons/header_pokemons_icon.svg"
import header_trainers_icon from "../../../assets/icons/header_trainers_icon.svg"
import header_rating_icon from "../../../assets/icons/header_rating_icon.svg"
import header_exit_icon from "../../../assets/icons/header_exit_icon.svg"
import HeaderUserBlock from "./HeaderUserBlock";
import {useAuth} from "../../../pages/UserCore/Auth/AuthContext";
import Cookies from "js-cookie";



const HeaderNav = () => {
    const { logout } = useAuth();

    const handleLinkTrainer = (e) => {
        Cookies.set('search_pokemons', '', { expires: 365 });
        Cookies.set('search_trainers', '', { expires: 365 });

        window.scrollTo(0, 0);
    }

    return (
        <nav>
            <InteractiveButton link="/" link_active={false} label="VS" class_name="header_interactive_button_logo" onClick={handleLinkTrainer} />
            <div className='nav_main'>
                <InteractiveButton link="/" class_name="header_interactive_button_link_to_pokemons" label="Покемоны" img={header_pokemons_icon} onClick={handleLinkTrainer} />
                <InteractiveButton link="/trainers" class_name="header_interactive_button_link_to_trainers" label="Тренеры" img={header_trainers_icon} onClick={handleLinkTrainer} />
                <InteractiveButton link="/rating" class_name="header_interactive_button_link_to_rating" label="Рейтинг" img={header_rating_icon} onClick={handleLinkTrainer} />
            </div>
            <div className='right_block'>
                <HeaderUserBlock />
                <InteractiveButton link="/" link_active={false} img={header_exit_icon} class_name="header_interactive_button_exit" onClick={async () => {await logout()}} />
            </div>
        </nav>
    );
};

export default HeaderNav;