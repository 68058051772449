import React from 'react';
import "./Error.css";

import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/ext-language_tools";

import errorStatus from "../../assets/images/error/error-status.png";
import errorImg from "../../assets/images/error/error-img.png";

import Cookies from "js-cookie";
import Header from "../../components/Layout/Header/Header";
import Footer from "../../components/Layout/Footer/Footer";


function Error({ status, title, is_over=true, is_hidden = 'block', is_all = '' }) {
    if (Cookies.get('user_auth') === '1'){
        return (
            <>
                {is_over && (<Header />)}
                <main id={is_all} className="content k_error_screen" style={{'display': is_hidden}}>
                    <div className="gradient"></div>
                    <div className="not-found container">
                        <div className='not-found__container'>
                            <h2 className="not-found__title">{title}</h2>
                            <div className="not-found__status">
                                <img
                                    src={errorStatus}
                                    className="not-found__status-bg"
                                    alt="облако"
                                />
                                <h1 className="not-found__text">{status}...</h1>
                            </div>
                            <img src={errorImg} className="not-found__pikachu" alt="пикачу" />
                        </div>
                    </div>
                </main>
                {is_over && (<Footer />)}
            </>
        );
    }else{
        return (
            <>
                {is_over && (<Header />)}
                <main id={is_all} className="content k_error_screen" style={{'display': is_hidden}}>
                    <div className="gradient"></div>
                    <div className="not-found container">
                        <div className='not-found__container'>
                            <h2 className="not-found__title">{title}</h2>
                            <div className="not-found__status">
                                <img
                                    src={errorStatus}
                                    className="not-found__status-bg"
                                    alt="облако"
                                />
                                <h1 className="not-found__text">{status}...</h1>
                            </div>
                            <img src={errorImg} className="not-found__pikachu" alt="пикачу" />
                        </div>
                    </div>
                </main>
                {is_over && (<Footer />)}
            </>
        );
    }


}

export default Error;