import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';

import './Button.css';

const InteractiveButton = ({link, label, show_label = true, img = '', class_name = '', onClick = function(){window.scrollTo(0, 0);}, link_active=true}) => {
    const location = useLocation();

    const renderImg = (label, img) => {
        if (img){
            return <div className='wrapper_img'><img className='interactive_button_img' src={img} alt={label} loading="lazy" /></div>;
        }

        return '';
    }

    return (
        <Link to={link} onClick={onClick} className={"style_1_interactive_button_link " + class_name + " " + (location.pathname === link && link_active ? 'active' : '')}>
            <div className="interactive_button">
                {renderImg(label, img)}
                {show_label ? label : ''}
            </div>
        </Link>
    );
};

InteractiveButton.propTypes = {
    link: PropTypes.string,
    label: PropTypes.string,
    show_label: PropTypes.bool,
    img: PropTypes.string,
    class_name: PropTypes.string,
    onClick: PropTypes.func,
    link_active: PropTypes.bool
};

export default InteractiveButton;