import React from 'react';
import PropTypes from 'prop-types';

import './Gradient.css';

const Gradient = ({type}) => {
    return (
        <div className={"gradient " + type}></div>
    );
};

Gradient.propTypes = {
    type: PropTypes.string
};

export default Gradient;