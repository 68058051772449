import React, { createContext, useContext, useState } from 'react';
import axios from "axios";
import Cookies from 'js-cookie';
import $ from "jquery";

const PokemonExportContext = createContext(undefined);

export const PokemonExportProvider = ({ children }) => {
    const [arrayStatusExport, setarrayStatusExport] = useState(false);

    const statusExport = async ({trainer_id}) => {

        const asyncFn = async ({trainer_id}) => {
            await axios.get(process.env.REACT_APP_TECH_API + '/battle_history_request?trainer_id='+trainer_id, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'trainer_token': Cookies.get('session_id')
                }
            }).then(async function (response) {
                if (response.status === 200) {
                    setarrayStatusExport(response.data)
                }
            })
        };

        await asyncFn({trainer_id});
    };

    const sendExport = async ({trainer_id}) => {

        const asyncFn = async ({trainer_id}) => {
            return await axios.post(process.env.REACT_APP_TECH_API + '/battle_history_request', {}, {
                headers: {
                    'Content-Type': 'application/json',
                    'trainer_token': Cookies.get('session_id')
                }
            })
        };

        return await asyncFn({trainer_id});
    };

    const clearExport = async () => {

        const asyncFn = async () => {
            setarrayStatusExport(false)
        };

        await asyncFn();
    };

    const value = { arrayStatusExport, statusExport, sendExport, clearExport };

    return <PokemonExportContext.Provider value={value}>{children}</PokemonExportContext.Provider>;
};

export const usePokemonExport = () => useContext(PokemonExportContext);