import React, { createContext, useContext, useState } from 'react';
import axios from "axios";
import Cookies from 'js-cookie';
import $ from "jquery";

const TrainerListContext = createContext(undefined);

export const TrainerListProvider = ({ children }) => {
    const [nextPage, setnextPage] = useState(false);
    const [currectPage, setCurrectPage] = useState(1);
    const [filteredTrainers, setFilteredTrainers] = useState([]);

    const load = async () => {
        const asyncFn = async () => {
            await axios.get(process.env.REACT_APP_NEW_API + '/trainers?', {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(function (response) {
                if (response.status === 200) {
                    setFilteredTrainers(response.data.data);
                    setnextPage(response.data.next_page);
                }
            })
        };

        asyncFn();
    };

    const sort_new = async ({load_more=false}) => {
        var data = {};

        data.sort = $('.k_coach_sort_pc .filter_type_sort_body_form_radio:checked').val();

        if (load_more){
            setCurrectPage(currectPage+1);

            data.page = currectPage+1;
        }else{
            setCurrectPage(1);

            data.page = 1;
        }

        var i_attack = 1;
        var menu_badge_val = 0;
        data.level = '';
        $('.k_filter_level .filter_type_burger_menu_body_filter_radio_p input').each(function () {
            if (i_attack === 6) {
                i_attack = 1;
            }

            if ($(this).prop('checked')) {
                data.level += i_attack + ',';

                menu_badge_val++;
            }
            i_attack++;
        });

        if (data.level !== '') {
            if (data.level) {
                data.level = data.level.slice(0, -1);
            }
        } else {
            delete data.level;
        }

        if (Cookies.get('search_trainers') !== '' && Cookies.get('search_trainers') !== undefined && Cookies.get('search_trainers') !== 'undefined') {
            data.name = Cookies.get('search_trainers');
        } else {
            delete data.name;
        }

        await axios.get(process.env.REACT_APP_NEW_API + '/trainers?' + $.param(data), {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(function (response) {
            if (response.status === 200) {
                if (response.data.data === 'Тренеры не найдены'){
                    setFilteredTrainers([]);
                    return
                }

                if (load_more){
                    setFilteredTrainers(filteredTrainers.concat(response.data.data))
                }else{
                    setFilteredTrainers(response.data.data);
                }

                setnextPage(response.data.next_page);
            }
        })
    };

    const value = { filteredTrainers, nextPage, sort_new, load };

    return <TrainerListContext.Provider value={value}>{children}</TrainerListContext.Provider>;
};

export const useTrainerList = () => useContext(TrainerListContext);