import React from 'react';
import "./PaymentResult.css";
import Cookies from 'js-cookie';
import $ from "jquery";
import PaymentHeader from "../../../components/Payment/PaymentHeader/PaymentHeader";
import PaymentReceipt from "../../../components/Payment/PaymentReceipt/PaymentReceipt";

import payment_success_icon from "../../../assets/icons/payment/payment_success_icon.svg";
import payment_mistake_icon from "../../../assets/icons/payment/payment_mistake_icon.svg";
import payment_blue_left_arrow_icon from "../../../assets/icons/payment/payment_blue_left_arrow_icon.svg";

function dayTitle(number) {
    if (number > 10 && [11, 12, 13, 14].includes(number%100)) return 'дней';
    var last_num = number%10;
    if (last_num == 1) return 'день';
    if ([2,3,4].includes(last_num)) return 'дня';
    if ([5,6,7,8,9, 0].includes(last_num)) return 'дней';
}

const PaymentResultPage = ({type='success'}) => {
    const [ProductPrice, SetProductPrice] = React.useState(0);
    const [ProductTitle, SetProductTitle] = React.useState(0);

    React.useEffect(() => {
        if (Cookies.get('user_buy_product') !== ''){
            if (Cookies.get('order_type') !== 'premium'){
                SetProductPrice(Cookies.get('user_buy_price'))
                SetProductTitle('Аватар Тренера № '+Cookies.get('user_buy_product'));

                if (type === 'success'){
                    Cookies.set('user_image', 'https://storage.yandexcloud.net/qastudio/pokemon_battle/trainers/'+('' + Cookies.get('user_buy_product')).padStart(2, '0')+'.png', {expires: 365});
                }

                $('body').on('click', '.link_back', function (){
                    setTimeout(function (){
                        window.location.href = '/shop';
                    }, 500);

                    return false;
                })
            }else{
                SetProductPrice(Cookies.get('premium_price'));

                if (type === 'success'){
                    Cookies.set('is_premium', true, { expires: 365 });
                    Cookies.set('premium_duration', Cookies.get('premium_day'), { expires: 365 });
                    Cookies.set('premium_success', 'yes', {expires: 365});
                }

                SetProductTitle('Pokemon Premium: ' + Cookies.get('premium_day') + ' ' + dayTitle(Cookies.get('premium_day')));
                setTimeout(function (){
                    $('body').on('click', '.link_back', function (){
                        setTimeout(function () {
                            window.location.href = '/premium';
                        }, 500);

                        return false;
                    })
                }, 500);
            }
        }

        Cookies.set('user_buy_product', '', {expires: 365});
        Cookies.set('user_buy_card', '', {expires: 365});
        Cookies.set('user_buy_card_full', '', {expires: 365});
        Cookies.set('user_buy_price', '', {expires: 365});
        Cookies.set('user_buy_code', '', {expires: 365});
        Cookies.set('user_buy_name', '', {expires: 365});
        Cookies.set('user_buy_date', '', {expires: 365});
    }, []);

    return (
        <div className="payment_page">
            <PaymentHeader text={false} />
            <main className="payment_page_content">
                <PaymentReceipt price={ProductPrice} product_title={ProductTitle}/>
                <form className="payment_form_card">
                    <div className={"payment_form_card_form" + (type === "success" ? ' payment_success' : ' payment_mistake')}>
                        <div className="payment_status_top">
                            <img className="success__image" alt="статус покупки" src={type === "success" ? payment_success_icon : payment_mistake_icon} />
                            <h3 className="payment_status_top_title style_1_caption_20_500">
                                {type === "success" ? 'Покупка прошла успешно' : 'При оплате произошла ошибка'}
                            </h3>
                        </div>
                        {type === "success" ? '' :
                            <div className="payment_status_top_text style_1_caption_14_500 style_1_text_grey">
                                Попробуйте снова или оплатите другим способом
                            </div>
                        }
                        <button className="payment_status_back">
                            <img alt="strelka" src={payment_blue_left_arrow_icon} className="payment_status_back_img" />
                            <p className="style_1_base_link_blue link_back">Вернуться в магазин</p>
                        </button>
                    </div>
                </form>
            </main>
        </div>
    );
};

export default PaymentResultPage;