import React from 'react';
import PropTypes from 'prop-types';

import './InputStandart.css';
import {TextField} from "@mui/material";

const InputStandart = ({name, label, type='text', value, touched, error, onBlur=function (){}, onChange=function (){}}) => {
    return (
        <TextField
            error={Boolean(touched && error)}
            fullWidth
            helperText={touched && error}
            label={label}
            margin="normal"
            name={name}
            id={"k_"+name}
            onBlur={onBlur}
            onChange={onChange}
            type={type}
            value={value}
            variant="outlined"
            className='k_form_control'
        />
    );
};

InputStandart.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string
};

export default InputStandart;