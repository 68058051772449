import React from 'react';

import "./PokemonAvatarChange.css";

import base_avatar_change_icon from "../../../assets/icons/base_avatar_change_icon.svg";
import {useNavigate} from "react-router-dom";
import base_arrow_left from "../../../assets/icons/base_arrow_left.svg";

const PokemonAvatarChange = () => {
    const navigate = useNavigate();

    const handleLinkPremium = (e) => {
        navigate('/shop', {replace: false});
    };

    return (
        <div className="k_trainer_in_button k_cart_trainer_avatar_change" onClick={handleLinkPremium}>
            <img src={base_avatar_change_icon} className="k_trainer_in_button_icon" />
            <div className="k_trainer_in_button_wrapper">
                <div className="k_trainer_in_button_title_no_desc k_trainer_in_button_title style_1_caption_16_400 style_1_text_left">Смена аватара</div>
            </div>
            <button id="dropdown" className="pokemon_one_body_content_inner_hystory_battle_link_button">
                <img src={base_arrow_left} width="26" height="26" alt="история битв" />
            </button>
        </div>
    );
};

export default PokemonAvatarChange;