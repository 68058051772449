import React, { createContext, useContext, useState } from 'react';
import axios from "axios";
import Cookies from 'js-cookie';
import $ from "jquery";

const AuthContext = createContext(undefined);

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    React.useEffect(() => {
        const asyncFn = async () => {
            const session_id = Cookies.get('session_id');
            const firebase_token = Cookies.get('firebase_token');

            if (session_id) {
                await axios.post(`${process.env.REACT_APP_NEW_API}/technical_routes/auth_control`, {action: 'auth', api_key: session_id, firebase_token: firebase_token}, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    withCredentials: true
                }).then(function (response) {
                    if (response.status === 200) {
                        setUser(response.data);
                    }
                }).catch(function (response) {
                    if (response.status !== 200) {
                        Cookies.set('user_auth', '0', { expires: 365 });
                        Cookies.set('session_id', '0', { expires: 365 });
                        setUser(null);

                        $('.auth').css('display', 'block');
                    }
                });
            } else {
                setUser(null);

                $('.auth').css('display', 'block');
            }
        };

        asyncFn();
    }, []);

    const login = async (values, {setErrors}) => {
        var formData = new FormData();
        formData.append('email', values['email']);
        formData.append('pass_1', values['password']);
        formData.append('action', 'auth_trainer');

        await axios.post(`${process.env.REACT_APP_NEW_API}/technical_routes/auth_control`, formData, {
            withCredentials: true
        }).then(function (response) {
            if (response.status === 200) {
                setUser(response.data);

                window.location.href = '/'
            }
        }).catch(function (response) {
            if (response.status !== 200) {
                setErrors({submit: response.response.data.message})
            }
        });
    };

    const logout = async () => {
        var formData = new FormData();
        formData.append('action', 'exit');

        await axios.post(`${process.env.REACT_APP_NEW_API}/technical_routes/auth_control`, formData, {
            withCredentials: true
        }).then(function (response) {
            if (response.status === 200) {
                Cookies.set('user_auth', '', { expires: 365 });
                Cookies.set('user_id', '', {expires: 365});
                setUser(null);

                window.location.reload();
            }
        });
    };

    const value = { user, login, logout };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);