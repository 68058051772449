import React from 'react';
import PropTypes from 'prop-types';

import './SubTitleAndSubText.css';

const SubTitleAndSubText = ({link, subtitle, subtext=false}) => {
    return (
        <>
            <div className="style_1_popup_white_title2">
                <a href={link} className="style_1_popup_white_title_prev"></a>
                <p className="register_title_h2 style_1_caption_16_500 style_1_text_left">{subtitle}</p>
            </div>
            {subtext ?
                <p className="register_subtitle style_1_caption_12_500 style_1_text_left style_1_text_grey" dangerouslySetInnerHTML={{__html: subtext}}></p>
            : ''}
        </>
    );
};

SubTitleAndSubText.propTypes = {
    link: PropTypes.string,
    subtitle: PropTypes.string,
    subtext: PropTypes.string
};

export default SubTitleAndSubText;