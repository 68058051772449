import React from 'react';
import {
    Box,
    FormHelperText,
} from '@mui/material';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import './ChangePasswordStep2.css';

import { useChangePasswordStep2 } from "./ChangePasswordStep2Context";
import ClassicTitle from "../../../components/PopupsWhite/ClassicTitle/ClassicTitle";
import SubTitleAndSubText from "../../../components/PopupsWhite/SubTitleAndSubText/SubTitleAndSubText";
import SendButtonStandart from "../../../components/PopupsWhite/SendButtonStandart/SendButtonStandart";
import LinkStandart from "../../../components/PopupsWhite/LinkStandart/LinkStandart";
import InputStandart from "../../../components/PopupsWhite/InputStandart/InputStandart";
import Gradient from "../../../components/Layout/Gradient/Gradient";

import Cookies from 'js-cookie';
import {kGetUrl} from "../../../helpers/helpers";

const ChangePasswordStep2Page = () => {
    const { allowChangePass, change_password_step_2, change_password_step_2_check_hash } = useChangePasswordStep2();

    React.useEffect(() => {
        change_password_step_2_check_hash();
    }, []);

    return (
        <>
            <Gradient type="gradient_bottom" />
            <section className="style_1_popup_white_out style_1_popup_all_page">
                <div className="style_1_popup_white_in">
                    <ClassicTitle title="Битва покемонов" />
                    <SubTitleAndSubText link="/login" subtitle="Создание нового пароля" subtext="Придумайте новый пароль. Минимум 6 символов, только латиница, 1 заглавная буква и 1 цифра" />

                    <Formik
                        initialValues={{
                            password_1: '',
                            password_2: ''
                        }}
                        validationSchema={Yup.object().shape({
                            password_2: Yup.string().oneOf([Yup.ref('password_1'), null], 'Пароли не совпадают').max(255).required('Пароли не совпадают'),
                            password_1: Yup.string().matches(/^[A-Z0-9._%+#$!@-]{2,}$/i, 'Новый пароль не соответствует требованиям.\n' + 'Минимум 6 символов, только латиница, 1 заглавная буква и 1 цифра').matches(/^\S+$/i, 'Новый пароль не соответствует требованиям.\n' + 'Минимум 6 символов, только латиница, 1 заглавная буква и 1 цифра').matches(/(?=.*[A-Z])(?=.*\d)/, 'Новый пароль не соответствует требованиям.\n' + 'Минимум 6 символов, только латиница, 1 заглавная буква и 1 цифра').min(6, 'Новый пароль не соответствует требованиям.\n' + 'Минимум 6 символов, только латиница, 1 заглавная буква и 1 цифра').max(255).required('Введите пароль'),
                        })}
                        onSubmit={async (values, {setErrors}) => {
                            await change_password_step_2(values, {setErrors});
                        }}
                    >
                        {({ errors, handleBlur, handleChange, isSubmitting, touched, values }) => (
                            <Form>
                                <div className="style_1_popup_white_form_wrapper_fields">
                                    <InputStandart label="Новый пароль" name="password_1" onBlur={handleBlur} onChange={handleChange} touched={touched.password_1} error={errors.password_1} value={values.password_1} />
                                    <InputStandart label="Повторите новый пароль" name="password_2" onBlur={handleBlur} onChange={handleChange} touched={touched.password_2} error={errors.password_2} value={values.password_2} />
                                </div>

                                {errors.submit && (
                                    <Box mt={3} className='k_error_submit'>
                                        <FormHelperText error style={{'margin-top': '-38px', 'margin-bottom': '44px'}}>{errors.submit}</FormHelperText>
                                    </Box>
                                )}

                                <SendButtonStandart title="Сохранить пароль" is_disabled={isSubmitting} />
                                <LinkStandart link="/login" title="Войти" />
                            </Form>
                        )}
                    </Formik>
                </div>
            </section>
        </>
    );
};

export default ChangePasswordStep2Page;