import React from 'react';
import {
    Box,
    FormHelperText,
    TextField,
    FormControl,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    IconButton
} from '@mui/material';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import './Registration.css';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { useRegistration } from "./RegistrationContext";
import Gradient from "../../../components/Layout/Gradient/Gradient";
import ClassicTitle from "../../../components/PopupsWhite/ClassicTitle/ClassicTitle";
import SocialAuth from "../../../components/PopupsWhite/SocialAuth/SocialAuth";
import SubTitleAndSubText from "../../../components/PopupsWhite/SubTitleAndSubText/SubTitleAndSubText";
import InputStandart from "../../../components/PopupsWhite/InputStandart/InputStandart";
import SendButtonStandart from "../../../components/PopupsWhite/SendButtonStandart/SendButtonStandart";
import LinkStandart from "../../../components/PopupsWhite/LinkStandart/LinkStandart";

const Registration = () => {
    const { register } = useRegistration();
    const [showPassword, setShowPassword] = React.useState(false);
    const [showPassword2, setShowPassword2] = React.useState(false);
    const url_to_google = 'https://id.vk.com/auth?uuid='+process.env.REACT_APP_TECH_API_VK_SECRET_PUBLIC_HASH+'&app_id='+process.env.REACT_APP_TECH_API_VK_APP_ID+'&response_type=silent_token&redirect_uri='+process.env.REACT_APP_NEW_API+'/technical_routes/callback_vk';

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleClickShowPassword2 = () => {
        setShowPassword2(!showPassword2);
    };

    const handleMouseDownPassword2 = (event) => {
        event.preventDefault();
    };

    return (
        <>
            <Gradient type="gradient_bottom" />
            <section className="style_1_popup_white_out style_1_popup_all_page">
                <div className="style_1_popup_white_in">
                    <ClassicTitle title="Битва покемонов" />
                    <SocialAuth title="Регистрация через соцсеть" />
                    <SubTitleAndSubText link="/login" subtitle="Регистрация" subtext='Токен можно получить в <a href="https://t.me/qa_studio_kotik_bot" target="_blank">Котике</a> → отправь <a href="https://storage.yandexcloud.net/qastudio/tracker/stage_create_trainer.png" target="_blank">команду /stage</a>' />

                    <Formik
                        initialValues={{
                            token: '',
                            email: '',
                            password: '',
                            password_2: ''
                        }}
                        validationSchema={Yup.object().shape({
                            token: Yup.string().matches(/^[A-Z0-9._%+#$!@-]{2,}$/i, 'Введите токен').max(255).required('Введите токен'),
                            email: Yup.string().matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,}$/i, 'Введите почту').matches(/^\S+$/i, 'Введите почту').max(255).required('Введите почту'),
                            password_2: Yup.string().oneOf([Yup.ref('password'), null], 'Пароли не совпадают').max(255).required('Пароли не совпадают'),
                            password: Yup.string().matches(/^[A-Z0-9._%+#$!@-]{2,}$/i, 'Пароль не соответствует\n' + 'требованиям').matches(/^\S+$/i, 'Пароль не соответствует\n' + 'требованиям').matches(/(?=.*[A-Z])(?=.*\d)/, 'Пароль не соответствует\n' + 'требованиям').min(6, 'Пароль не соответствует\n' + 'требованиям').max(255).required('Введите пароль'),
                        })}
                        onSubmit={async (values, {setErrors}) => {
                            await register(values, {setErrors});
                        }}
                    >
                        {({ errors, handleBlur, handleChange, isSubmitting, touched, values }) => (
                            <Form>
                                <div className="style_1_popup_white_form_wrapper_fields">
                                    <InputStandart label="Токен" name="token" onBlur={handleBlur} onChange={handleChange} touched={touched.token} error={errors.token} value={values.token} />

                                    <TextField
                                        error={Boolean(touched.email && errors.email)}
                                        fullWidth
                                        helperText={touched.email && errors.email}
                                        label="Почта"
                                        margin="normal"
                                        name="email"
                                        id="k_email"
                                        autoComplete="off"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="text"
                                        value={values.email}
                                        variant="outlined"
                                        className='k_form_control'
                                    />

                                    <div className="k_field_passwords">
                                        <FormControl className='k_form_control' fullWidth error={Boolean(touched.password && errors.password)}>
                                            <InputLabel htmlFor="k_password">Пароль</InputLabel>
                                            <OutlinedInput
                                                id="k_password"
                                                type={showPassword2 ? 'text' : 'password'}
                                                value={values.password}
                                                name="password"
                                                className="k_form_f_pass_1"
                                                autoComplete="off"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Пароль"
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword2}
                                                            onMouseDown={handleMouseDownPassword2}
                                                            edge={"end" + (showPassword2 ? ' active' : '')}
                                                            size="large"
                                                            style={values.password.length > 0 ? {'display': 'block'} : {'display': 'none'}}
                                                        >
                                                            {showPassword2 ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                            {touched.password && errors.password && (
                                                <FormHelperText error id="standard-weight-helper-text">
                                                    {' '}
                                                    {errors.password}{' '}
                                                </FormHelperText>
                                            )}
                                            <p className="k_password_info style_1_caption_12_500 style_1_text_left" aria-invalid="false">
                                                Минимум 6&nbsp;символов, только латиница, 1&nbsp;заглавная буква и&nbsp;1&nbsp;цифра
                                            </p>
                                        </FormControl>

                                        <FormControl className='k_form_control' fullWidth error={Boolean(touched.password_2 && errors.password_2)}>
                                            <InputLabel htmlFor="k_password">Повторите пароль</InputLabel>
                                            <OutlinedInput
                                                id="k_password_2"
                                                type={showPassword ? 'text' : 'password'}
                                                value={values.password_2}
                                                name="password_2"
                                                className="k_form_f_pass_2"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Повторите пароль"
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge={"end" + (showPassword ? ' active' : '')}
                                                            size="large"
                                                            style={values.password_2.length > 0 ? {'display': 'block'} : {'display': 'none'}}
                                                        >
                                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                            {touched.password_2 && errors.password_2 && (
                                                <FormHelperText error id="standard-weight-helper-text">
                                                    {' '}
                                                    {errors.password_2}{' '}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </div>

                                </div>


                                {errors.submit && (
                                    <Box mt={3} className='k_error_submit'>
                                        <FormHelperText error>{errors.submit}</FormHelperText>
                                    </Box>
                                )}

                                <SendButtonStandart title="Зарегистрироваться" is_disabled={isSubmitting}/>
                                <LinkStandart link="/login" title="Уже зарегистрированы? Войти"/>
                            </Form>
                        )}
                    </Formik>
                </div>
            </section>
        </>
    );
};

export default Registration;