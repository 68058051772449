import React, { useState, useEffect } from 'react';
import "./Profile.css";

import base_arrow_left from "../../assets/icons/base_arrow_left.svg";

import Cookies from 'js-cookie';

import {useProfile} from "./ProfileContext";
import {KCheckField} from "../../helpers/helpers";
import $ from "jquery";
import {useAuth} from "../UserCore/Auth/AuthContext";
import BackButton from "../../components/Interactive/BackButton/BackButton";
import {useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import base_export_icon from "../../assets/icons/base_export_icon.svg";

import settings_token_icon from "../../assets/icons/settings/settings_token_icon.svg"

function k_get(name){
    if(name=(new RegExp('[?&]'+encodeURIComponent(name)+'=([^&]*)')).exec(window.location.search))
        return decodeURIComponent(name[1]);
}

$('body').on('click', '.k_google_connect .delete_method', function (){
    $.ajax({
        url: process.env.REACT_APP_NEW_API + '/technical_routes/auth_control',
        type: 'POST',
        dataType: 'json',
        data: {action: 'delete_vk', trainer_token: Cookies.get('session_id')},
        success: function (data) {
            if (data.status == 'success') {
                Cookies.set('vk_auth_id', null, { expires: 365 });

                k_render_profile();
            }
        }
    });
});

$('body').on('click', '.k_telegram_connect .delete_method', function (){
    $.ajax({
        url: process.env.REACT_APP_NEW_API + '/technical_routes/auth_control',
        type: 'POST',
        dataType: 'json',
        data: {action: 'delete_t', trainer_token: Cookies.get('session_id')},
        success: function (data) {
            if (data.status == 'success') {
                Cookies.set('telegram_name', null, { expires: 365 });

                k_render_profile();
            }
        }
    });
});

const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

$('body').on('click', '.k_send_mail_confirm', function (e){
    e.preventDefault();
    let k_error = 0;

    if (KCheckField('.k_form_f_pass', 'pass') !== true){
        k_error++;
    }

    if ($('.k_input_mail_confirm').val() !== '') {
        if (validateEmail($('.k_input_mail_confirm').val())) {
            $('.k_input_mail_confirm').parent().find('.auth__error').fadeOut();
            $('.k_input_mail_confirm').attr('aria-invalid', "false");
        } else {
            $('.k_input_mail_confirm').parent().find('.auth__error').html('Введите корректную почту');
            $('.k_input_mail_confirm').parent().find('.auth__error').fadeIn();
            $('.k_input_mail_confirm').attr('aria-invalid', "true");
            k_error++;
        }
    } else {
        $('.k_input_mail_confirm').parent().find('.auth__error').html('Введите почту');
        $('.k_input_mail_confirm').parent().find('.auth__error').fadeIn();
        $('.k_input_mail_confirm').attr('aria-invalid', "true");
        k_error++;
    }

    if ( k_error === 0) {

        $.ajax({
            url: process.env.REACT_APP_NEW_API + '/technical_routes/auth_control',
            type: 'POST',
            dataType: 'json',
            data: {action: 'email_send_profile', email: $('.k_input_mail_confirm').val(), trainer_token: Cookies.get('session_id'), trainer_id: Cookies.get('user_id'), pass_1: $('.k_form_reg_pass_1').val(), pass_2: $('.k_form_reg_pass_2').val()},
            success: function (data) {
                if (data.message === 'Успешно') {
                    Cookies.set('login', $('.k_input_mail_confirm').val(), {expires: 365});
                    $('.k_input_mail_renew').val($('.k_input_mail_confirm').val());
                    $('.k_input_mail_confirm').parent().find('.auth__error').fadeOut();
                    $('.k_input_mail_confirm').attr('aria-invalid', "true");

                    k_render_profile();
                }
            },
            error: function (data){
                data = JSON.parse(data.responseText)
                console.log(data)

                var show_pass = false;

                if(data.message === 'Аккаунт с такой почтой уже зарегистрирован' || data.message === "Почта занята"){
                    $('.k_form_reg_mail_error').text('Аккаунт с такой почтой уже зарегистрирован');
                    $('.k_form_reg_mail_error').fadeIn();
                    $('.k_input_mail_confirm').attr('aria-invalid', "true");

                    show_pass = true;
                }

                if(show_pass === false){
                    $('.k_form_reg_pass_1').parent().find('.auth__error').text(data.message);
                    $('.k_form_reg_pass_1').parent().find('.auth__error').fadeIn();
                }
            }
        });
    }

    return false;
});

$('body').on('click', '.k_send_mail_renew', function (){
    $('.k_profile_email_confirm').css('display', 'none');
    $('.k_profile_email_register').css('display', 'block');

    return false;
});

if (k_get('hash')){
    $.ajax({
        url: process.env.REACT_APP_NEW_API + '/technical_routes/auth_control',
        type: 'POST',
        dataType: 'json',
        data: {action: 'email_check', trainer_id: k_get('trainer_id'), client_key: k_get('hash')},
        success: function (data) {
            if (data.message === 'Успешно') {
                var save_trainer_id = k_get('trainer_id');

                Cookies.set('email_confirm', true, { expires: 365 });
                $('.k_pochta_success').css('display', 'block');
                $('.k_pochta_pass').html(data.password);

                k_render_profile();
                const url = new URL(document.location);
                const searchParams = url.searchParams;
                searchParams.delete("hash");
                searchParams.delete("trainer_id");
                window.history.pushState({}, '', '/trainer/'+save_trainer_id);
                window.history.pushState({}, '', url.toString());
            }
        },
        error: function (data){
            data = JSON.parse(data.responseText);
            $('.k_pochta_error .k_pochta_error_m').html(data.message);
            $('.k_pochta_error').css('display', 'block');
        }
    });
}

if (k_get('connect_trainer_page')){
    const url = new URL(document.location);
    const searchParams = url.searchParams;
    searchParams.delete("connect_trainer_page");
    window.history.pushState({}, '', '/trainer/'+Cookies.get('user_id'));
    window.history.pushState({}, '', url.toString());
}


function k_render_profile(){
    $.ajax({
        url: process.env.REACT_APP_NEW_API + '/technical_routes/auth_control',
        type: 'POST',
        dataType: 'json',
        data: {action: 'auth', api_key: Cookies.get('session_id'), firebase_token: Cookies.get('firebase_token')},
        success: function (data) {
            $('body').css('visibility', 'visible');
            if (data.hash) {
                Cookies.set('user_id', data.id, { expires: 365 });
                Cookies.set('user_image', 'https://storage.yandexcloud.net/qastudio/pokemon_battle/trainers/'+('' + data.avatar_id).padStart(2, '0')+'.png', { expires: 365 });

                Cookies.set('login', data.login, { expires: 365 });
                Cookies.set('vk_auth_id', data.vk_auth_id, { expires: 365 });
                Cookies.set('user_auth', '1', { expires: 365 });
                Cookies.set('is_premium', data.is_premium, { expires: 365 });
                Cookies.set('premium_duration', data.premium_duration, { expires: 365 });

                Cookies.set('email_confirm', data.email_confirm, { expires: 365 });
                setTimeout(function (){
                    var url_to_google = 'https://id.vk.com/auth?uuid='+process.env.REACT_APP_TECH_API_VK_SECRET_PUBLIC_HASH+'&app_id='+process.env.REACT_APP_TECH_API_VK_APP_ID+'&response_type=silent_token&redirect_uri='+process.env.REACT_APP_NEW_API+'/technical_routes/callback_vk';

                    var none_p = '<p class="profile__subtitle text-center">Невозможно отвязать<br /> единственный способ</p>';
                    var button_disconnect = '<div type="submit" class="profile__button profile__button-right profile__button-light delete_method">Отвязать</div>';
                    var button_connect = '<div type="submit" class="profile__button profile__button-right">Привязать</div>';

                    $('.k_google_connect').html('')
                    $('.k_email_connect').html('')

                    var max_methods = 0;
                    if (Cookies.get('login') && Cookies.get('login') !== 'null' && Cookies.get('email_confirm') && Cookies.get('email_confirm') !== 'null' && Cookies.get('email_confirm') !== 'false'){
                        max_methods += 1;
                    }

                    if (Cookies.get('vk_auth_id') && Cookies.get('vk_auth_id') !== 'null'){
                        max_methods += 1;
                    }

                    if (Cookies.get('vk_auth_id') && Cookies.get('vk_auth_id') !== 'null'){
                        $('.k_google_name').html(Cookies.get('vk_auth_id'));
                        $('.k_google_name').removeClass('profile__text-grey');
                        $('.k_google_connect').html('');

                        if (max_methods <= 1) {
                            $('.k_google_connect').append(none_p)
                        }else{
                            $('.k_google_connect').append(button_disconnect)
                        }
                    }else{
                        $('.k_google_name').html('Не привязан');
                        $('.k_google_name').addClass('profile__text-grey');
                        $('.k_google_connect').html('');
                        $('.k_google_connect').append(button_connect)
                        $('.k_google_connect > div').off('click');
                        $('.k_google_connect > div').on('click', function (){
                            window.location.href = url_to_google;
                        });
                        Cookies.set('google_add', 'true', { expires: 100 });
                    }

                    if (Cookies.get('login') && Cookies.get('login') !== 'null' && Cookies.get('email_confirm') && Cookies.get('email_confirm') !== 'null' && Cookies.get('email_confirm') !== 'false'){
                        $('.k_email_name').html(Cookies.get('login'));
                        $('.k_email_name').removeClass('profile__text-grey');
                        $('.k_email_connect').html('');
                        $('.k_email_connect').append('<p class="profile__subtitle text-center">Невозможно отвязать</p>');
                    }else{
                        $('.k_email_name').html('Не привязан');
                        $('.k_email_name').addClass('profile__text-grey');
                        console.log(Cookies.get('email_confirm'));
                        if (Cookies.get('email_confirm') === false || Cookies.get('email_confirm') === 'false' || Cookies.get('email_confirm') === 'null' && Cookies.get('login') && Cookies.get('login') !== 'null'){
                            $('.k_profile_email_register').css('display', 'none');
                            $('.k_profile_email_confirm').css('display', 'block');
                        }else{
                            $('.k_profile_email_confirm').css('display', 'none');
                            $('.k_profile_email_register').css('display', 'block');
                        }

                        $('.k_profile_email_ok').css('display', 'none');
                    }

                    if (k_get('error')){
                        $('.k_pochta_error .k_pochta_error_m').html('Привязка аккаунта<br>'+k_get('message'));
                        $('.k_pochta_error').css('display', 'block');

                        const url = new URL(document.location);
                        const searchParams = url.searchParams;
                        searchParams.delete("error");
                        searchParams.delete("message");
                        window.history.pushState({}, '', url.toString());
                    }
                }, 500);
            }else{
                window.location.href = '/login';
                Cookies.set('user_auth', '0', { expires: 365 });
            }
        },
        error: function (data){
            window.location.href = '/login';
            Cookies.set('user_auth', '0', { expires: 365 });
        }
    });
}

const ProfilePage = () => {
    k_render_profile();

    const { user } = useAuth();
    const { rating, lastUpdate, load } = useProfile();

    let navigate = useNavigate();
    const {
        register,
        getValues,
        handleSubmit,
        reset,
        formState: {errors},
    } = useForm({
        mode: "onSubmit",
    });

    const [toggle, setToggle] = useState(true);
    const handleChange = (event) => {
        const input = event.target;
        const icon = input.closest('.auth__wrap').querySelector('.auth__input-icon');
        icon.style.display='block';
        if(input.value.length == 0) {
            icon.style.display='none';
        }

        KCheckField('.k_form_f_pass', 'pass')
    }

    $('body').off('change keyup', '.k_input_mail_confirm');
    $('body').on('change keyup', '.k_input_mail_confirm', function (){
        if ($('.k_input_mail_confirm').val() !== '') {
            if (validateEmail($('.k_input_mail_confirm').val())) {
                $('.k_input_mail_confirm').parent().find('.auth__error').fadeOut();
                $('.k_input_mail_confirm').attr('aria-invalid', "false");
            } else {
                $('.k_input_mail_confirm').parent().find('.auth__error').html('Введите корректную почту');
                $('.k_input_mail_confirm').parent().find('.auth__error').fadeIn();
                $('.k_input_mail_confirm').attr('aria-invalid', "true");
            }
        } else {
            $('.k_input_mail_confirm').parent().find('.auth__error').html('Введите почту');
            $('.k_input_mail_confirm').parent().find('.auth__error').fadeIn();
            $('.k_input_mail_confirm').attr('aria-invalid', "true");
        }
    });

    const handleClick = (event) => {
        event.preventDefault();

        const icon = event.target;
        const input = icon.closest('.auth__wrap').querySelector('.auth__input-pass');
        if(!$(icon).hasClass('toggle')) {
            input.type = 'text';
            icon.classList.add('view-pass');
            icon.classList.add('toggle');
        }  else {
            input.type = 'password';
            icon.classList.remove('view-pass');
            icon.classList.remove('toggle');
        }
    }

    Cookies.set('K_global_gets_trainer', 0, { expires: 365 });

    $('#password, .k_form_f_pass_2').off('change');
    $('#password, .k_form_f_pass_2').on('change', function (){
        var error_pass = KCheckField('.k_form_f_pass', 'pass')

        if (error_pass === true) {
            $('.k_form_f_pass_2').parent().find('.auth__error').fadeOut();
            $('.k_form_f_pass_2').removeClass('k_f_error');

            $('.k_form_f_pass_1').parent().find('.auth__error').fadeOut();
            $('.k_form_f_pass_1').removeClass('k_f_error');
        }else{
            $('#password').next().next().next().html(error_pass)
        }

    });

    function copy_number_f(event){
        var el = $(event.target).closest('.copy_number');
        var el_id = $(el).find('.copy_number_id');
        var el_button = $(el).find('.copy_number_button');

        event.stopPropagation();
        if (el.attr('data-block') !== 'true') {
            navigator.clipboard.writeText(el_id.text()).then(function() {
                console.log('Async: Copying to clipboard was successful!');
            }, function(err) {
                console.error('Async: Could not copy text: ', err);
            });

            setTimeout(function ( el_button, el ) {
                el_button.css('opacity', '0.3');
                el.attr('data-block', 'true');
            }, 50, el_button, el);

            setTimeout(function ( el_button ) {
                el_button.css({'opacity': '0.5', 'scale': '0.5'});
            }, 100, el_button);

            setTimeout(function ( el_button ) {
                el_button.css({'opacity': '1', 'scale': '1'});
            }, 200, el_button);

            setTimeout(function ( el_id ) {
                el_id.fadeOut(100);
            }, 300, el_id);

            setTimeout(function ( el ) {
                if (el.attr('data-last') === '' || !el.attr('data-last')) {
                    el_id.attr('data-last', el_id.text());
                    el_id.text('Скопировано');
                }

                el_id.fadeIn(300);
            }, 400, el, el_id);

            setTimeout(function ( el_id ) {
                el_id.fadeOut(100);
            }, 1000, el_id);

            setTimeout(function ( el_id, el ) {
                el_id.fadeIn(300);
                el_id.text(el_id.attr('data-last'));
                el_id.attr('data-last', '');
                el.attr('data-block', 'false');
            }, 1100, el_id, el);
        }
    }

    return (
        <div className="profile_page">
            <main className="content">
                <div className="gradient auth_gradient"></div>
                <BackButton />
                <section className="auth profile">
                    <div className="login__content profile">
                        <div className="profile-mobile">
                            <div onClick={() => navigate(-1)} className="profile__back-button-mobile">
                                <img src={base_arrow_left} alt="вернуться назад" />
                            </div>
                            <h2 className="profile__title-mobile">Настройки входа</h2>
                        </div>

                        <h1 className="profile__title">Настройки входа</h1>

                        <div className="profile__container p-22 k_pochta_success" style={{ display: "none" }}>
                            <div>
                                <div className="k_pochta_ok">Почта успешно подтверждена</div>
                            </div>
                        </div>

                        <div className="profile__container p-22 k_pochta_error" style={{ display: "none" }}>
                            <div>
                                <div className="k_pochta_error_m"></div>
                            </div>
                        </div>

                        <form action="#" className="auth__form profile__email-f profile__container k_profile_email_register" noValidate={true} style={{ display: "none" }}>
                            <h4 className="profile__label">Почта и пароль</h4>
                            <p className="profile__subtitle mb-16">
                                Потребуется подтверждение. <br /> После подтверждения невозможно отвязать
                            </p>
                            <div className="auth__wrap">
                                <input type="email" className="auth__input profile__input k_input_mail_confirm" placeholder=" "/>
                                <label className="auth__label">Почта</label>
                                <span className="auth__error k_form_reg_mail_error" style={{ display: "none" }}>Введите почту</span>
                            </div>
                            <div className="df register__df">
                                <div className="auth__wrap">
                                    <input
                                        type='password'
                                        id='password'
                                        className="auth__input auth__input-pass k_form_reg_pass_1 k_form_f_pass_1"
                                        {...register("password", {
                                            required: "Введите пароль",
                                            minLength: {
                                                value: 6,
                                                message: "Минимум 6 символов",
                                            },
                                        })}
                                        aria-invalid={errors.password ? "true" : "false"}
                                        placeholder=' '
                                        onChange={handleChange}
                                    />
                                    <label className="auth__label">Пароль</label>

                                    <span
                                        className="auth__input-icon"
                                        onClick={handleClick}
                                    ></span>
                                    <span className="auth__error" style={{ display: "none", 'white-space': "nowrap" }}></span>
                                    <p className="auth__subtitle register__title register__title_er"
                                       aria-invalid={errors.password ? "true" : "false"}>
                                        Придумайте пароль. Минимум 6&nbsp;символов, только латиница, 1&nbsp;заглавная буква и&nbsp;1&nbsp;цифра
                                    </p>
                                </div>
                                <div className="auth__wrap">
                                    <input type='password'
                                           id='confirm-password'
                                           className="auth__input auth__input-pass k_form_reg_pass_2 k_form_f_pass_2"
                                           {...register("pass", {
                                               validate: (match) => {
                                                   const password = getValues("password")
                                                   return match === password || "Пароли не совпадают"
                                               },
                                               required: "Пароли не совпадают",
                                           })}
                                           aria-invalid={errors.pass ? "true" : "false"}
                                           placeholder=' '
                                           onChange={handleChange}
                                    />
                                    <label className="auth__label">Повторите пароль</label>
                                    <span
                                        className="auth__input-icon"
                                        onClick={handleClick}
                                    ></span>
                                    <span className="auth__error" style={{ display: "none" }}>{errors.pass?.message}</span>
                                </div>
                            </div>
                            <button type="submit" className="profile__button profile__button-right k_send_mail_confirm">
                                Привязать
                            </button>
                        </form>

                        <form action="#" className="auth__form profile__email-f profile__container k_profile_email_confirm" noValidate={true} style={{ display: "none" }}>
                            <h4 className="profile__label">Почта и пароль</h4>
                            <p className="profile__subtitle mb-16">
                                Мы отправили подтверждение на вашу почту. Чтобы завершить, перейдите по ссылке в письме
                            </p>
                            <p className="profile__subtitle mb-16">
                                Нет письма? Проверьте папку «Спам»
                            </p>
                            <div className="auth__wrap" style={{ opacity: "0.6" }}>
                                <input type="email" defaultValue={Cookies.get('login')} disabled="true" className="auth__input profile__input k_input_mail_renew" placeholder=" "/>
                                <label className="auth__label">Почта</label>
                                <span className="auth__error" style={{ display: "none" }}>Введите почту</span>
                            </div>
                            <button type="submit" className="profile__button profile__button-right k_send_mail_renew">
                                Изменить
                            </button>
                        </form>

                        <div className="profile__container p-22 flex k_profile_email_ok">
                            <div>
                                <p className="profile__title-grey">Почта</p>
                                <div className="profile__text profile__text-grey k_email_name k_profile_title_left">Не привязан</div>
                            </div>

                            <div className='k_email_connect'></div>
                        </div>

                        <div action="#" className="profile__container p-22 flex">
                            <div>
                                <p className="profile__title-grey">VK</p>
                                <div className="profile__text profile__text-grey k_google_name k_profile_title_left">Не привязан</div>
                            </div>

                            <div className='k_google_connect'></div>
                        </div>
                    </div>
                    <div className="k_cart_trainer_export">
                        <img src={settings_token_icon} className="k_cart_trainer_export_icon" />
                        <div className="k_cart_trainer_export_box">
                            <div className="k_cart_trainer_export_box_title style_1_caption_16_400 style_1_text_left">Мой токен</div>
                            <div className="k_cart_trainer_export_box_desc style_1_caption_12_400 style_1_text_left">
                                <div className="copy_number" onClick={copy_number_f}>
                                    <div className="copy_number_id">{Cookies.get('session_id')}</div>
                                    <button className="copy_number_button"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    );
};

export default ProfilePage;