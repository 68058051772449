import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import base_arrow_vector_icon from "../../../assets/icons/base_arrow_vector_icon.svg"

import "./../FilterTypeSort.css";
import $ from "jquery";
import {useTrainerList} from "../../../pages/TrainerList/TrainerListContext";

const FilterTypeSort = () => {
    const [currectSort, setCurrectSort] = React.useState('asc_date');
    const [currectSortTitle, setCurrectSortTitle] = React.useState('Сначала новые');


    const [showSort, setShowSort] = React.useState(false);
    const { sort_new } = useTrainerList();

    const handleOpenSort = (event) => {
        setShowSort(!showSort);
    };

    const handleChangeSort = async (e) => {
        setCurrectSort(e.target.value);
        setCurrectSortTitle($(e.target).prev().html());

        handleOpenSort();

        await sort_new(currectSort);
    }

    React.useEffect(() => {
        function closePopup() {
            setShowSort(false);
        }

        function handlecloseSort(e) {
            const target = e.target.closest(".filter_type_sort");

            if (!target) {
                closePopup();
            }
        }

        $(document).off('click', handlecloseSort);
        $(document).on('click', handlecloseSort);
    }, []);

    return (
        <div className="filter_type_sort">
            <div className={"filter_type_sort_select style_1_interactive_button_link " + (showSort ? 'open' : 'close')} onClick={handleOpenSort}>
                <div className="filter_type_sort_select_title">{currectSortTitle}</div>
                <div className="filter_type_sort_select_arrow style_1_arrow">
                    <img src={base_arrow_vector_icon}/>
                </div>
            </div>

            <div className={"filter_type_sort_body " + (showSort ? 'open' : 'close')}>
                <button className="filter_type_burger_menu_body_icon_close" onClick={handleOpenSort} />
                <h3 className="style_1_caption_24_500 style_1_text_left">Сортировка</h3>
                <form className="filter_type_sort_body_form k_coach_sort_pc">
                    <label className="filter_type_sort_body_form_label style_1_caption_16_500">
                        <font>Сначала новые</font>
                        <input type="radio" name="radio" value="asc_date" checked={currectSort === 'asc_date' ? 'true' : ''} className="filter_type_sort_body_form_radio" onClick={handleChangeSort} />
                        <span className="filter_type_sort_body_form_radio_custom"></span>
                    </label>
                    <label className="filter_type_sort_body_form_label style_1_caption_16_500">
                        <font>По убыванию уровня</font>
                        <input type="radio" name="radio" value="desc_level" className="filter_type_sort_body_form_radio" onClick={handleChangeSort} />
                        <span className="filter_type_sort_body_form_radio_custom"></span>
                    </label>
                    <label className="filter_type_sort_body_form_label style_1_caption_16_500">
                        <font>По возрастанию уровня</font>
                        <input type="radio" name="radio" value="asc_level" className="filter_type_sort_body_form_radio" onClick={handleChangeSort} />
                        <span className="filter_type_sort_body_form_radio_custom"></span>
                    </label>
                    <label className="filter_type_sort_body_form_label style_1_caption_16_500">
                        <font>По алфавиту</font>
                        <input type="radio" name="radio" value="asc" className="filter_type_sort_body_form_radio" onClick={handleChangeSort} />
                        <span className="filter_type_sort_body_form_radio_custom"></span>
                    </label>
                </form>
            </div>
            <div className="filter_type_burger_menu_body_bg"></div>
        </div>
    );
};

export default FilterTypeSort;