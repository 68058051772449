import React from 'react';
import TrainersListCard from "../../components/Pokemons/TrainersList/TrainersListCard";
import {useTrainerList} from "./TrainerListContext";
import none_trainer from "../../assets/images/none_trainer.png"
import "./TrainerList.css";

const TrainerListPage = (sort_info) => {
    const { nextPage, filteredTrainers, load, sort_new } = useTrainerList();

    React.useEffect(() => {
        load()
    }, []);

    const handleLoadMore = async () => {
        await sort_new({load_more:true})
    };

    return (
        <div>
            <div className="trainers_list">
                <div className="gradient"></div>
                {filteredTrainers && filteredTrainers.length > 0 ? filteredTrainers.map((trainer) => (
                    <TrainersListCard trainer={trainer} />
                )) :
                    <div className="empty">
                        <img className="empty__img" src={none_trainer} alt="картинка"/>
                        <h2 className="empty__title">Никого не найдено</h2>
                        <p className="empty__subtitle">Попробуйте изменить параметры запроса</p>
                    </div>
                }
            </div>
            {nextPage && filteredTrainers && filteredTrainers.length > 0 ?
                <div className="k_load_more">
                    <button className="style_1_caption_16_500 style_1_button_background" onClick={handleLoadMore}>Загрузить еще</button>
                </div> : ''}
        </div>
    );
};

export default TrainerListPage;