import React from 'react';
import "../Payment.css";
import Cookies from 'js-cookie';
import {usePayment3DS} from "./Payment3DSContext";
import $ from "jquery";
import {NavLink, useNavigate} from "react-router-dom";
import PaymentHeader from "../../../components/Payment/PaymentHeader/PaymentHeader";
import PaymentReceipt from "../../../components/Payment/PaymentReceipt/PaymentReceipt";
import PaymentFormCard from "../../../components/Payment/PaymentFormCard/PaymentFormCard";
import base_arrow_left_black from "../../../assets/icons/base_arrow_left_black.svg";
import axios from "axios";
import PaymentMainTitle from "../../../components/Payment/PaymentMainTitle/PaymentMainTitle";
import PaymentForm3DS from "../../../components/Payment/PaymentForm3DS/PaymentForm3DS";

var global_timer_is_run = false

function dayTitle(number) {
    if (number > 10 && [11, 12, 13, 14].includes(number%100)) return 'дней';
    var last_num = number%10;
    if (last_num == 1) return 'день';
    if ([2,3,4].includes(last_num)) return 'дня';
    if ([5,6,7,8,9, 0].includes(last_num)) return 'дней';
}

const Payment3DSPage = () => {
    const [ProductPrice, SetProductPrice] = React.useState(0);
    const [ProductTitle, SetProductTitle] = React.useState(0);

    var k_id = 0;

    const getAvatars = async () => {
        return await axios.get(process.env.REACT_APP_NEW_API + '/debug_menu/get_avatars', {
            headers: {
                'Content-Type': 'application/json',
                'trainer_token': Cookies.get('session_id')
            }
        })
    }

    React.useEffect(() => {
        if (Cookies.get('order_type') !== 'premium'){
            var data = getAvatars().then(async function (response) {
                data = response.data.data;

                for (var i = 0; i < data.length; i++){
                    if ((+data[i].id) === +window.location.href.split('/')[ window.location.href.split('/').length - 1 ]){
                        SetProductPrice(data[i].price);
                        k_id = data[i].id;
                    }
                }

                SetProductTitle('Аватар Тренера № '+k_id);
                $('.payment_form_card_back_wrapper').attr('href', '/shop');
            });
        }else{
            SetProductPrice(Cookies.get('premium_price'));
            k_id = 0;

            SetProductTitle('Pokemon Premium: ' + Cookies.get('premium_day') + ' ' + dayTitle(Cookies.get('premium_day')));
            $('.payment_form_card_back_wrapper').attr('href', '/premium');
        }
    }, []);

    return (
        <div className="payment_page">
            <PaymentHeader text={false} />
            <main className="payment_page_content">
                <PaymentMainTitle />
                <PaymentReceipt type="card"/>
                <PaymentForm3DS price={ProductPrice} />
            </main>
        </div>
    );
};

export default Payment3DSPage;